import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagosrComponent,  DialogDataExampleDialogComponent } from './pagos_r.component';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
// import { PagosService } from './pagos.service';

import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatMomentDateModule } from "@angular/material-moment-adapter";

const routes = [
  {
      path     : 'pagos_r',
      component: PagosrComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
   
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    SweetAlert2Module,
    MatIconModule,
    TranslateModule,
    AgGridModule.withComponents([]),
    FuseSharedModule,
    HttpClientModule, HttpModule,
    FuseConfirmDialogModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
MatToolbarModule,
MatInputModule,
MatFormFieldModule
  ],
  exports     : [
    PagosrComponent
],
  declarations: [PagosrComponent, DialogDataExampleDialogComponent],
  entryComponents: [DialogDataExampleDialogComponent]
})
export class PagosrModule { }
