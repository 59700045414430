import { FuseMatSidenavHelperDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { Component, ViewChild, OnInit, RendererStyleFlags2, OnDestroy, AfterViewInit, Input, Inject } from '@angular/core';
import { ProjectTracking } from './project_tracking.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import { LoginService } from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;

}

/* interface Fases {
  value: string;
  viewValue: string;
    service: string;
} */



@Component({
  selector: 'fuse-project_tracking',
  templateUrl: './project_tracking.component.html',
  styleUrls: ['./project_tracking.component.scss']
})
export class ProjectTrackingComponent implements OnInit {
  public loading = false;
/*   fases: Fases[] = [
    {value: '0', viewValue: 'Toma de contacto', service: '1'},
    {value: '1', viewValue: 'Reunión confirmada', service: '1'},
    {value: '2', viewValue: 'Reunión terminada', service: '2'},
    {value: '3', viewValue: 'Realización de diseño conceptual', service: '2'},
    {value: '4', viewValue: 'Diseño confirmado/PRESENTACIÓN GRÁFICA en proceso', service: '3'},
    {value: '5', viewValue: 'Presentación gráfica terminada', service: '3'},
    {value: '6', viewValue: 'Realización de modificaciones', service: '1'},
    {value: '7', viewValue: 'TERMINADO (pdf final)', service: '1'},


  ]; */
 


  public gridApi;
  private gridColumnApi;
  public gridOptions;
  // private rowData: any[];
  trigger: any;
  // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  clientetoadd: any;
  datatosend: any;
  sometrig: any;
  datatomod: any;
  columnDefs: any;
  personas: any;
  carMappings: any;
  carMappings_R: any;
  editrigger =  false;
  newItem: any;
  carMappings_R3: any;
  columnTypes: any;
  serviciostosend: any;
  sectorid: any;
  serviciostoadd: any[];
  presup: any[];
  personastoadd: any;
  rowData: any;
  arm: any;
  statistic: any;
  workers: any;
  table: any;
  htmlid: any;
  fases: any;
  fase: any;
 
  public trig2;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>; private components;
  constructor(private projects: ProjectTracking, private router: Router,
    private loginService1: LoginService, public dialog: MatDialog) {
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.htmlid = "algo";

  }

  ngOnInit() {
    this.loading = true;
    this.projects.getFases()
    .subscribe(res => {
      this.fases = res.fases
console.log(this.fases)
this.projects.getAllProject()
    .subscribe(res2 => {
      this.table = res2.projects
console.log(res2)
this.loading = false;
    });
    });
    

    

  
  




    this.trigger = false;
    // this.getPersonas();
    this.trig2 = false;
    this.sometrig = false;
    // this.getSectores();
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') || localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response) {
          if (rescat.response === 1) {



            // this.getPersonas();
          }
          else {

            this.router.navigate(['/login']);
          }
        }
        else {
          this.router.navigate(['/login']);
        }

      })
    }






    // this.getProyectos(this.carMappings);
  }



  openDialog(item): void {
    /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
     console.log(dataformod2);
     this.proyectos.modProyectos(dataformod2.data)
     .subscribe(res => {
         
       console.log(res);
           }); */


    const dialogRef = this.dialog.open(DialogDataExampleDialogComponent, {
      width: '450px',
      height: '450px',
      data: {
        item: item
      }

    });

    dialogRef.afterClosed().subscribe(result => {
/*       var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
      rowNode.setDataValue('cliente', result.nombre);
      rowNode.setDataValue('email', result.email);
     
      var dataformod = this.gridApi.getRowNode(rowindex);
      console.log(dataformod);
      console.log(dataformod.data.id);
      console.log(result.id_cliente);
      this.clientetoadd = result.id_cliente; */
      /*      if (dataid.id){
           this.proyectos.modProyectos2(dataformod.data.id, result.id_cliente)
           .subscribe(res => {
               
             console.log(res);
                 });
                }
                else{
      
      this.clientetoadd = result.id_cliente;
      console.log(this.clientetoadd)
                } */
    });
  }














  editCom(e){
    console.log(e)
    this.editrigger = true 
    this.newItem = e.id
    (document.getElementById(e.id) as any).disabled = false;
    
  }

saveCom(e){


(document.getElementById(e.id) as any).disabled  = true;
this.editrigger = false;
this.newItem = null
this.projects.update(e).subscribe(res => {
console.log(res)

});
}

saveData3(event){
console.log(event)


}

someFunction(event){

  console.log(event)
}
saveData2( event, item){
  console.log(event)
  // if (event.isUserInput && event.source.value !== event.source.viewValue) { 
 // console.log(fase)
  console.log(item)
  console.log(event)

this.projects.updateFase(event.value.id, item).subscribe(res => {
  console.log(res)

})

  
// }
}



compareFn(c1: any, c2: any): boolean {
  console.log("eav")
  return c1 && c2 ? c1.fase_name === c2 : c1 === c2;
}
  dateFormatter(params) {

    // return params.value ? (new Date(params.value)).toLocaleDateString() : '';
    var timestamp = Date.parse(params.value);

    if (isNaN(timestamp) === false) {
      var d = new Date(timestamp).toLocaleDateString();
      console.log(d)
      return d

    }
    console.log("222222222")
    // return timestamp
  }





}








@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  item: any;
  members: any;
  @Input() sideBar: ProjectTrackingComponent;

  public bankCtrl: FormControl = new FormControl();
  private clientes: any;

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */



  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);



 @ViewChild('multipleSelect') multipleSelect: MatSelect;


  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();


  ngOnInit() {

    this.projects.getUsuarios()
    .subscribe(res => {
this.members = res.members;
console.log(this.members)
// this.servicios.push({id: 3, nombre: "Elegir servicio", plazo:0, precio:0});
//
// this.bankCtrl.setValue(this.members[2]);
    


    this.filteredBanks.next(this.members.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })





    // listen for search field value changes

  }

  getlistCliente() {


  };
  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multipleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;

      });
  }
  private filterBanks() {
    if (!this.members) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.members.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the clientes
    this.filteredBanks.next(
      this.members.filter(members => members.nombre.toLowerCase().indexOf(search) > -1)
    );
  }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
  constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>, private projects: ProjectTracking, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.item = data.item

   }

  saveData(bankCtrl) {
    console.log(bankCtrl.value);
 //   this.dialogRef.close({ id_cliente: bankCtrl.value.id, nombre: bankCtrl.value.nombre, email: bankCtrl.value.email });
  }
  saveData1(res) {
    console.log(res);
   // this.dialogRef.close({ age: this.armtest, username: this.username });
  }
}

































