
import {Injectable} from '@angular/core';
// import {AuthHttp} from 'angular2-jwt';
import {Response, Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Injectable(/* {
  providedIn: 'root'
} */)
export class ProyectosService {
  private baseUrl = 'http://lfdi.bitronedev.com/public/';
constructor(public http: Http ) {


   }
   getClientes(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}clientes/getClientes2`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  getServicios2(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}servicios/getServicios2`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getRRHH2(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}rrhh/getRRHH2`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  getSectores(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}sectores/getSectores2`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }



   getProyectos(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}proyectos/getProyectos`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getProyectosById(id): any {

    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + id 
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}proyectos/getProyectosById`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  addProyectos(datatosend, cliente, sectorid, personas,  servicios_id, presup): Observable<any> {
    console.log(datatosend);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'nombre=' + datatosend.nombre + '&servicios=' + datatosend.servicios + '&sectornames=' + datatosend.sector + '&cliente=' + cliente + '&personas_encargados=' +  datatosend.personas_encargados + '&personas=' +  personas + '&fecha_ini=' +  datatosend.fecha_ini + '&sector=' + sectorid + '&manager=' + datatosend.manager + '&estado=' + datatosend.estado  + '&comentarios=' + datatosend.comentarios + '&servicios_id=' + servicios_id + '&importe=' + presup  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}proyectos/addProyectos2`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }



  modProyectos( datatomod, servicios, clientetoadd, personastoadd, servicios_id ): any {
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre + '&servicios=' + servicios + '&cliente=' + clientetoadd + '&personas_encargados=' + personastoadd + '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado  + '&comentarios=' + datatomod.comentarios + '&servicios_id=' + servicios_id ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos`, body,  options).map(this.extractData2).catch(this.handleError);
  }




  modProyectos2( datatomod ): any {
    console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre + '&servicios=' + datatomod.servicios + '&cliente=' + datatomod.cliente + '&personas_encargados=' + datatomod.personas_encargados + '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado  + '&comentarios=' + datatomod.comentarios  ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos2`, body,  options).map(this.extractData2).catch(this.handleError);
  }


  modProyectosXXX( datatomod1, datatomod2, datatomod3, datatomod4, presup): any {
   // console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod1 +  '&servicios=' + datatomod2 + '&proyecto=' + datatomod3 + '&cliente=' + datatomod4 + '&importe=' + presup;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectosXXX`, body,  options).map(this.extractData2).catch(this.handleError);
  }




  modProyectosSector( datatomod1, datatomod2): any {
    // console.log(datatomod);
     
     // let jwt = localStorage.getItem('id_token');
    // let user_id= localStorage.getItem('user_login');
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let body = 'id=' + datatomod1 +  '&sectores=' + datatomod2 ;
     let authHeader = new Headers();
 
     if (jwt) {
         authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
 
       authHeader.append('Authorization', jwt);
     //  console.log(authHeader);
     }
 
     let options = new RequestOptions({headers: authHeader});
  //   console.log(body);
 
     return this.http.post(`${this.baseUrl}proyectos/modProyectosSector`, body,  options).map(this.extractData2).catch(this.handleError);
   }



   modProyectosPersonas( datatomod1, datatomod2): any {
    // console.log(datatomod);
     
     // let jwt = localStorage.getItem('id_token');
    // let user_id= localStorage.getItem('user_login');
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let body = 'id=' + datatomod1 +  '&personas=' + datatomod2 ;
     let authHeader = new Headers();
 
     if (jwt) {
         authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
 
       authHeader.append('Authorization', jwt);
     //  console.log(authHeader);
     }
 
     let options = new RequestOptions({headers: authHeader});
  //   console.log(body);
 
     return this.http.post(`${this.baseUrl}proyectos/modProyectosPersonas`, body,  options).map(this.extractData2).catch(this.handleError);
   }


  modProyectos3( datatomod, personastoadd ): any {
    console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre +  '&personas_encargados=' + personastoadd + '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado  + '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos3`, body,  options).map(this.extractData2).catch(this.handleError);
  }


  modProyectos4( datatomod, personastoadd, clientetoadd ): any {
    console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre +  '&personas_encargados=' + personastoadd + '&cliente=' + clientetoadd + '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado  + '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos4`, body,  options).map(this.extractData2).catch(this.handleError);
  }

  modProyectos5( datatomod, personastoadd,  servicios_id, servicios  ): any {
    console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre +  '&personas_encargados=' + personastoadd + '&servicios=' + servicios + '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado + '&servicios_id=' + servicios_id  + '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos5`, body,  options).map(this.extractData2).catch(this.handleError);
  }

  modProyectos6( datatomod,  servicios_id, servicios, clientetoadd  ): any {
    console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre +  '&cliente=' + clientetoadd + '&servicios=' + servicios + '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado + '&servicios_id=' + servicios_id  + '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos6`, body,  options).map(this.extractData2).catch(this.handleError);
  }

  modProyectos7( datatomod,  servicios_id, servicios  ): any {
    console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre  + '&servicios=' + servicios + '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado + '&servicios_id=' + servicios_id  + '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos7`, body,  options).map(this.extractData2).catch(this.handleError);
  }



  modProyectos8( datatomod,  clientetoadd  ): any {
    console.log(datatomod);
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id +  '&nombre=' + datatomod.nombre +  '&cliente=' + clientetoadd +  '&fecha_ini=' + datatomod.fecha_ini + '&sector=' + datatomod.sector + '&manager=' + datatomod.manager + '&estado=' + datatomod.estado +  '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}proyectos/modProyectos8`, body,  options).map(this.extractData2).catch(this.handleError);
  }





  remProyectos(datatosend): Observable<any> {
   
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + datatosend  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}proyectos/remProyectos`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }
  /**
   * Extracting data.
   *
   * @param res
   * @returns {any|{}}
   */
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private extractData2(res: Response) {
    
    return res;
  }
 /**
  * Handling errors.
  *
  * @param error
  * @returns {ErrorObservable}
  */
 private handleError(error: any) {
   let errMsg = (error.message) ? error.message :
     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
   console.log(errMsg); // log to console instead
   return Observable.throw(errMsg);
 }
}

