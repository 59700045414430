import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 import { ServiceEnLineComponent,  DialogDataExampleDialog5Component } from './ServiceEnLine.component';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ServiceEnLineService } from './ServiceEnLine.service';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatSelectModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const routes = [
  {
      path     : 'ServiceEnLine',
      component: ServiceEnLineComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    
    MatIconModule,
    TranslateModule,
    AgGridModule.withComponents([]),
    FuseSharedModule,
    HttpClientModule, HttpModule,
    FuseConfirmDialogModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule
  ],
  exports     : [
    ServiceEnLineComponent

  ],
  declarations: [ServiceEnLineComponent, DialogDataExampleDialog5Component],
  providers: [ServiceEnLineService],
  entryComponents: [DialogDataExampleDialog5Component],
})
export class ServiceEnLineModule { }
