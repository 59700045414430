import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectoresComponent } from './sectores.component';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SectoresService } from './sectores.service';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
const routes = [
  {
      path     : 'sectores',
      component: SectoresComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    
    MatIconModule,
    TranslateModule,
    AgGridModule.withComponents([]),
    FuseSharedModule,
    HttpClientModule, HttpModule,
    FuseConfirmDialogModule
  ],
  exports     : [
    SectoresComponent
],
  declarations: [SectoresComponent],
  providers: [SectoresService]
})
export class SectoresModule { }
