
import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
 import {VentasService} from '../../ventas/ventas.service';
 import {PresupuestosService} from '../../presupuestos/presupuestos.service';
 import {PagosService} from '../../pagos/pagos.service';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import * as moment from 'moment';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
interface Pais {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './anadirpagocomponent.component.html',
  styleUrls: ['./anadirpagocomponent.component.scss']
})
export class AnadirpagocomponentComponent implements OnInit, OnDestroy, AfterViewInit {
totalapagar: any;
  pagado: any;
  armtest: any;
  client: any;
  CIF: any;
  idtoupdate: any;
  telefono: any;
  email: any;
  fecha: any;
  selectedValue: any;
  username: any;
  proyectos: any;
  servicios: any;
  Clientes2: any ;
  numero_presup: any;
  choosedservice: any;
  servicename: any;
  fechafalta: any;
  descrip: any;
direccion: any;
codigo_postal: any;
ciudad: any;
empresa: any;
remitentes: any;
ventas_id: any;
  triger: any;
  servicedata: any;
  selectedValue2: any;
  importe_total: any;
  paisctrl: any;
selection: any;
fechapresup: any;
//  @Input() sideBar: VentasComponent; 
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */
  paises: Pais[] = [
    {value: 'spain', viewValue: 'España'},
    {value: 'otro', viewValue: 'Resto del Mundo'},
   
  ];
  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.fechapresup = ''
    this.remitentes = [
      {id:0, nombre:'LFDI', value:1},
      {id:1, nombre:'TIE', value:2},
      {id:2, nombre:'Begoña', value:3},
      {id:3, nombre:'YLENIA', value:4},
      {id:4, nombre:'Carlos', value:5},
      {id:5, nombre:'Sara', value:6},
      {id:6, nombre:'Maria', value:7},
      {id:7, nombre:'David', value:8},
    ]

    this.triger = 0;
    this.selection = "1";
/*     this.presupuestos.getlastid().subscribe(res2 => {
    console.log(res2.presupuestos2[0].numero)
    let presunum = res2.presupuestos2[0].numero
    this.numero_presup = parseInt(presunum, 10)
    this.numero_presup = this.numero_presup + 1
    console.log(this.numero_presup)
    }) */
/*     this.presupuestos.getServicios()
    .subscribe(res => {
this.servicios = res.servicios;
console.log(this.servicios)
this.servicios.push({id: 3, nombre: "Elegir servicio", plazo: 0, precio: 0});
console.log(this.servicios)
this.bankCtrl.setValue(this.servicios[22]);
    


    this.filteredBanks.next(this.servicios.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    }) */
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<AnadirpagocomponentComponent>, private presupuestos: PresupuestosService, private pagos: PagosService, private ventas: VentasService, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.client = data.cliente
      this.selectedValue = data.proyecto
      this.fecha = data.fecha
      this.email = data.email
      this.telefono = data.tel
      
      this.idtoupdate = data.uid;
      console.log(data)

      this.presupuestos.getServiciosbypresup(this.client)
      .subscribe(res => {
        console.log(res)
  this.servicios = res.presupuestos3;
  console.log(this.servicios)
  if (this.servicios.length == 1){
    let remit1 = this.servicios[0].remitente
    let remit = parseInt(remit1, 10)
    console.log(remit)
    let obj = this.remitentes.find(o => o.id === remit);
    console.log(obj)
    this.selectedValue2 = obj.nombre

  }
  // this.servicios.push({id: 3, nombre: "Elegir servicio", plazo: 0, precio: 0});
  
  this.bankCtrl.setValue(this.servicios[0]);
      
  
  
      this.filteredBanks.next(this.servicios.slice());
      this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
      })
    }
    



pasarAcliente(){


}

    saveData(bankCtrl){

  this.triger = 0;
      console.log(bankCtrl.value);
      this.servicename = bankCtrl.value.nombre;
      this.choosedservice = bankCtrl.value.id_servicio;
      var y: number = + bankCtrl.value.importe;
      this.totalapagar = y;
      console.log(this.choosedservice)
        this.servicedata = bankCtrl.value;
        let remit1 = this.servicedata.remitente
        let remit = parseInt(remit1, 10)
        console.log(remit)
        let obj = this.remitentes.find(o => o.id === remit);
        console.log(obj)
        this.selectedValue2 = obj.nombre

    }
                                                
 
     

      saveDataTotal(fecha, client, selectedValue, selection,  pagado, totalapagar, CIF,  telefono, email, direccion, codigo_postal, ciudad, empresa, paisctrl, descrip, selectedValue2){
   console.log(selectedValue)
   console.log(selectedValue2)
   console.log(selection)
   console.log(client)
   console.log(pagado)
   console.log(totalapagar)
   console.log(paisctrl)


   console.log(fecha)
        if (fecha === undefined || fecha === ""){
alert("Te falta la fecha ");
this.fechafalta = 1;
   } /* else if (this.choosedservice === undefined || this.choosedservice === ""){
    alert("Te falta el servicio ");
   } */
   else if (pagado === undefined || pagado === ""){
    alert("Te falta el pagado ");
   }
   else if (totalapagar === 0 || totalapagar === ""){
    alert("Te falta el totalapagar ");
   }
   else if (client === undefined || client === ""){
    alert("Te falta el cliente ");
   }
   else if ( selectedValue === undefined || selectedValue === ""){
    alert("Te falta el proyecto ");
   }
   else{    
// let tipo = typeof importe1;
// console.log(tipo);
console.log(fecha._d)
var datefecha = new Date(fecha); 
console.log(datefecha);
var dd = datefecha.getDate();
var mm = datefecha.getMonth() + 1; 
var yyyy = datefecha.getFullYear(); 
console.log(dd);
console.log(mm);
console.log(yyyy);
var datefecha1 = dd + '/' + mm + '/' + yyyy; 
console.log(datefecha1);
var datefecha2 = datefecha1.toLocaleString();
        console.log(datefecha2);
       // console.log(concepto1);
        console.log(this.selection)
    //    console.log(descrip);
       // console.log(importe1);
       // console.log(this.servicedata);
    //    this.importe_total = (importe1 + importe2 + importe3 + importe4 + importe5 + importe6 + importe7 + importe8).toFixed(0)

        this.pagos.addPagos(client, selectedValue, this.servicios[0].id_servicio, totalapagar, pagado, datefecha1)
      .subscribe(res => {
        console.log(res);

        this.ventas.addClientes(client, CIF, datefecha1, selectedValue, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip,   this.servicios[0].id_servicio, this.servicios[0].servicio, this.idtoupdate)
        .subscribe(res2 => {
          console.log(res2);
          if (res.response.status === "1"){
            this.ventas.modVentas2(this.idtoupdate).subscribe(res3 => {
              console.log(res3);

              this.ventas.createInvoice(client, CIF, datefecha1, selectedValue, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip,   this.servicios[0].id_servicio, this.servicios[0].servicio, pagado, paisctrl, selectedValue2.id).subscribe(res4 => {
                console.log(res4);
                this.dialogRef.close({result: "ik" });
              })
             
            })
            
          }
      
        })




        this.dialogRef.close()
/* 
        if (res.response.status === 1){
          console.log(this.importe_total);
  let sig_numero_presup = this.numero_presup
          this.presupuestos.generatePDF(sig_numero_presup, this.choosedservice, datefecha2, client, selectedValue, selection, descrip, concepto1, concepto2, concepto3, concepto4, concepto5, concepto6, concepto7, concepto8, importe1, importe2, importe3, importe4, importe5, importe6, importe7, importe8, this.importe_total)
          .subscribe(res2 => {
            console.log(res2);
            var fullDate = datefecha;
            var twoDigitMonth = fullDate.getMonth() + "";
            console.log(twoDigitMonth);
            if (twoDigitMonth.length === 1){
                twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length === 1){
                twoDigitDate = "0" + twoDigitDate;
            }
            
            
            console.log(twoDigitMonth);
            var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; 
            console.log(currentDate);
            var datefecha3 = dd + '-' + mm + '-' + yyyy; 
            this.dialogRef.close({servicio: this.servicename, proyecto: selectedValue, client: client,  fecha: datefecha3, concepto1: concepto1, concepto2: concepto2, concepto3: concepto3, concepto4: concepto4, concepto5: concepto5, concepto6: concepto6, concepto7: concepto7, concepto8: concepto8, importe1: importe1, importe2: importe2, importe3: importe3, importe4: importe4, importe5: importe5, importe6: importe6, importe7: importe7, importe8: importe8, numero: this.numero_presup, importe_total: this.importe_total });
          })

        } */

      })
    }
    }
  }


