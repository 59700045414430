import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';

import { FuseConfirmDialog3Component } from '@fuse/components/confirm-dialog3/confirm-dialog3.component';

@NgModule({
    declarations: [
        FuseConfirmDialog3Component
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        FuseConfirmDialog3Component
    ],
})
export class FuseConfirmDialog3Module
{
}
