import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
import {Contactos2Service} from './contactos2.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {Anadirpagocomponent2Component} from '../anadirpagocomponent2/anadirpagocomponent2.component'
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';

import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './contactos2.component.html',
  styleUrls  : ['./contactos2.component.scss'],
})
export class Contactos2Component implements OnInit  {
  public loading = false;
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  curfecha: any;
  name: any;
proyecto: any;
  dat: any;
  armtest: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  sidebar: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  private components;


/** control for the selected bank */














  
  constructor(private contactos: Contactos2Service,  private router: Router,
    private loginService1: LoginService,   public dialog: MatDialog) {
 
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';

  }
  ngOnInit() {
    // set initial selection
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getClientes2();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }

    this.trigger = false;
  // this.getClientes2();

  }





/** list of banks filtered by search keyword */






anadirpago(rowindex): void {
  this.curfecha = new Date()
  console.log(this.curfecha)
  console.log(this.name)

  const dialogRef = this.dialog.open(Anadirpagocomponent2Component, {
    width: '800px',
    height: '800px',
    data: {
      cliente: this.name,
      proyecto: this.proyecto,
      fecha: this.curfecha
    }
     });
  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
     });
}







openDialog(rowindex): void {
  const dialogRef = this.dialog.open(DialogDataExampleDialogComponent, {
    width: '450px',
    height: '450px',
    
  });

  dialogRef.afterClosed().subscribe(result => {
    var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
    rowNode.setDataValue('apellido',  result.age);
   // this.armtest = result.age;
   var dataformod = this.gridApi.getRowNode(rowindex);
   this.contactos.modClientes(dataformod.data)
   .subscribe(res => {
       
     console.log(res);
         });
   console.log(this.armtest);
  });
}
  getClientes2(){
    this.columnDefs = [];

    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }






    this.loading = true;
    this.contactos.getContactos()
    .subscribe(res => {
       this.rowData = res.contacts;

      console.log(res);
   

        for (let key in res.contacts[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key, hide: true,  filter: 'agTextColumnFilter', rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }        


  else  if (key === 'telefono') {
    this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  editable: true  };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}


        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', 
          
          filterParams: {
            filterOptions: ['contains', 'notContains'],
            textFormatter: function(r) {
              if (r == null){ return null}
              var r = r.toLowerCase();
              // r = r.replace(new RegExp("\\s", 'g'),"");
              r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
              r = r.replace(new RegExp('æ', 'g'), 'ae');
              r = r.replace(new RegExp('ç', 'g'), 'c');
              r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
              r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
              r = r.replace(new RegExp('ñ', 'g'), 'n');
              r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
              r = r.replace(new RegExp('œ', 'g'), 'oe');
              r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
              r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
              return r;
            },
            debounceMs: 0,
            caseSensitive: false,
           // suppressAndOrCondition: true,
          },
          
          editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
      
          this.loading = false;


      });
    
  }
/*  
  setPriceOnToyota() {   
    var rowNode = this.gridApi.getRowNode("aa");
    var newPrice = Math.floor(Math.random() * 100000);
    rowNode.setDataValue("price", newPrice);
  } */

  saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.contactos.addClientes(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }

  addItems() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({ add: newItems });
    printResult(res);
  }

  addItemsAtIndex() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({
      add: newItems,
      addIndex: 2
    });
    printResult(res);
  }

  updateItems() {
    var itemsToUpdate = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    printResult(res);
  }

  onInsertRowAt2() {
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: 0
    });
    printResult(res);
  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.contactos.remClientes(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onCellDoubleClicked(event1: any) { console.log('row', event1); 

if (event1.colDef.field === 'fecha_contacto' && this.addbutton === false){

  var armindex = this.gridApi.getFocusedCell();
  console.log(armindex);
  // this.gridApi.stopEditing();
 // this.openDialog(armindex.rowIndex);

}

}

testfunc(){

 var armpordz = this.gridApi.getRowNode(0);
 console.log(armpordz);
}


  onRowClicked(event: any) { console.log('row', event);
  this.name = event.data.nombre;
this.proyecto = event.data.proyecto;
this.butonrowclicked = true;
}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.contactos.modClientes(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
  
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.addbutton = false;
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: '' 
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  @Input() sideBar: Contactos2Component;
  
  public bankCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();
  
  /** list of banks */
  private banks: Bank[] = [
    {name: 'Bank A (Switzerland)', id: 'A'},
    {name: 'Bank B (Switzerland)', id: 'B'},
    {name: 'Bank C (France)', id: 'C'},
    {name: 'Bank D (France)', id: 'D'},
    {name: 'Bank E (France)', id: 'E'},
    {name: 'Bank F (Italy)', id: 'F'},
    {name: 'Bank G (Italy)', id: 'G'},
    {name: 'Bank H (Italy)', id: 'H'},
    {name: 'Bank I (Italy)', id: 'I'},
    {name: 'Bank J (Italy)', id: 'J'},
    {name: 'Bank Kolombia (United States of America)', id: 'K'},
    {name: 'Bank L (Germany)', id: 'L'},
    {name: 'Bank M (Germany)', id: 'M'},
    {name: 'Bank N (Germany)', id: 'N'},
    {name: 'Bank O (Germany)', id: 'O'},
    {name: 'Bank P (Germany)', id: 'P'},
    {name: 'Bank Q (Germany)', id: 'Q'},
    {name: 'Bank R (Germany)', id: 'R'}
  ];
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    // set initial selection
    this.bankCtrl.setValue(this.banks[10]);
    
console.log(this.banks);
    // load the initial bank list
    this.filteredBanks.next(this.banks.slice());
 

    // listen for search field value changes
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    }
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.banks) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.banks.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.filteredBanks.next(
        this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>) {}
    
    saveData(bankCtrl){
      console.log(bankCtrl.value.id);
      this.dialogRef.close({age: bankCtrl.value.id, username: bankCtrl.value.name});
      }
      saveData1(res){
        console.log(res);
        this.dialogRef.close({age: this.armtest, username: this.username});
        }
}
