import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { ClientesService } from './clientespend.service';
import { ClientespendComponent, DialogDataExampleDialogComponent } from './clientespend.component';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
const routes = [
    {
        path     : 'clientespend',
        component: ClientespendComponent
    }
];

@NgModule({
    declarations: [
        ClientespendComponent,
        DialogDataExampleDialogComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        SweetAlert2Module,
        MatIconModule,
        NgxLoadingModule,
        TranslateModule,
        AgGridModule.withComponents([]),
        FuseSharedModule,
        HttpClientModule, HttpModule,
        FuseConfirmDialogModule,
        MatInputModule,
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule
   
    
    ],
    exports     : [
        ClientespendComponent
    ],
    providers: [ClientesService],
    entryComponents: [DialogDataExampleDialogComponent],

 
})

export class ClientespendModule
{
}
