import { ProyectosComponent } from './../proyectos/proyectos.component';
import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
import {TempproService} from './temp_pro.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import {PresupuestosService} from '../presupuestos/presupuestos.service';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import {AnadirpagocomponentComponent} from '../addpago/anadirpagocomponent/anadirpagocomponent.component'
import { MatSelect, VERSION } from '@angular/material';
// import {Anadirpagocomponent2Component} from '../anadirpagocomponent2/anadirpagocomponent2.component'
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
interface Bank2 {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './temp_pro.component.html',
  styleUrls  : ['./temp_pro.component.scss'],
})
export class TempproComponent implements OnInit  {
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  servicio: any;
  name: any;
  proyecto: any;
  curfecha: any;
  uid: any;
  tel: any;
email: any;
  armtest: any;
  clientepresup: any;
  proyectopresup: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  sidebar: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  private components;
  form: FormGroup;
  uploadResponse;

/** control for the selected bank */














  
  constructor(private clientes: TempproService,  private router: Router, private formBuilder: FormBuilder,
    private loginService1: LoginService,   public dialog: MatDialog) {
 
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';

  }
  ngOnInit() {
    // set initial selection
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getClientes2();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }

    this.trigger = false;
  // this.getClientes2();

  }





/** list of banks filtered by search keyword */




openDialog(rowindex): void {
  this.curfecha = new Date()
  console.log(this.curfecha)
  const dialogRef = this.dialog.open(DialogDataExampleDialogComponent, {
    width: '800px',
    height: '800px',
    data: {
      /* cliente: this.name,
      proyecto: this.proyecto,
      fecha: this.curfecha,
      uid: this.uid */
    }
    
  });

  dialogRef.afterClosed().subscribe(result => {

    window.location.reload() 
/*     var newItem =  {
      cliente: result.client,
      proyecto: result.proyecto,
      servicio: result.servicio,
      fecha: result.fecha,
      numero: result.numero,
      importe: result.importe_total

    }; */
  
   // var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0 });
   // window.open('http://lfdi.bitronedev.com/public/' + result.numero + '.pdf', "_blank");
    
  });
}



openDialog2(rowindex): void {
  this.curfecha = new Date()
  console.log(this.curfecha)
  const dialogRef = this.dialog.open(DialogDataExampleDialog2Component, {
    width: '800px',
    height: '800px',
    data: {
servicio: this.servicio,
      uid: this.uid
    }
    
  });

  dialogRef.afterClosed().subscribe(result => {
    window.location.reload() 
    
  });
}







  getClientes2(){
    this.columnDefs = [];

    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }







    this.clientes.getProyectosForRAll()
    .subscribe(res => {
      this.rowData = res.proyectos;
      console.log(res);

        for (let key in res.proyectos[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  hide: true, filter: 'agTextColumnFilter', rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }        
      else  if (key === 'fase') {
        this.dat = {headerName: key, field: key, hide: true, filter: 'agTextColumnFilter',  width: 100, editable: true,    cellEditor: "datePicker" };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }

  else  if (key === 'codigo') {
    this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, editable: true};
    console.log('      key:',  key);

    this.columnDefs.push(this.dat);
     console.log(this.columnDefs);

}
  
  else  if (key === 'fasename') {
    this.dat = {headerName: 'Fase', field: key,  filter: 'agTextColumnFilter',   editable: false  };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'nombre_cliente') {
  this.dat = {headerName: 'Cliente', field: key,  filter: 'agTextColumnFilter',  width: 100, editable: false,
  filterParams: {
    filterOptions: ['contains', 'notContains'],
    textFormatter: function(r) {
      if (r == null){ return null}
      var r = r.toLowerCase();
      // r = r.replace(new RegExp("\\s", 'g'),"");
      r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
      r = r.replace(new RegExp('æ', 'g'), 'ae');
      r = r.replace(new RegExp('ç', 'g'), 'c');
      r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
      r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
      r = r.replace(new RegExp('ñ', 'g'), 'n');
      r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
      r = r.replace(new RegExp('œ', 'g'), 'oe');
      r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
      r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
      return r;
    },
    debounceMs: 0,
    caseSensitive: false,
   // suppressAndOrCondition: true,
  },  };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
  
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable: false,
          filterParams: {
            filterOptions: ['contains', 'notContains'],
            textFormatter: function(r) {
              if (r == null){ return null}
              var r = r.toLowerCase();
              // r = r.replace(new RegExp("\\s", 'g'),"");
              r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
              r = r.replace(new RegExp('æ', 'g'), 'ae');
              r = r.replace(new RegExp('ç', 'g'), 'c');
              r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
              r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
              r = r.replace(new RegExp('ñ', 'g'), 'n');
              r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
              r = r.replace(new RegExp('œ', 'g'), 'oe');
              r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
              r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
              return r;
            },
            debounceMs: 0,
            caseSensitive: false,
           // suppressAndOrCondition: true,
          },
          
          };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    



      });
  }
/*  
  setPriceOnToyota() {   
    var rowNode = this.gridApi.getRowNode("aa");
    var newPrice = Math.floor(Math.random() * 100000);
    rowNode.setDataValue("price", newPrice);
  } */

  saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.clientes.addClientes(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }

  addItems() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({ add: newItems });
    printResult(res);
  }

  addItemsAtIndex() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({
      add: newItems,
      addIndex: 2
    });
    printResult(res);
  }

  updateItems() {
    var itemsToUpdate = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    printResult(res);
  }

  onInsertRowAt2() {
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: 0
    });
    printResult(res);
  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.clientes.remClientes(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onCellDoubleClicked(event1: any) { console.log('row', event1); 

if (event1.colDef.field === 'fecha_contacto' && this.addbutton === false){

  var armindex = this.gridApi.getFocusedCell();
  console.log(armindex);
  // this.gridApi.stopEditing();
 // this.openDialog(armindex.rowIndex);

}

}

testfunc(){

 var armpordz = this.gridApi.getRowNode(0);
 console.log(armpordz);
}


  onRowClicked(event: any) {
     console.log('row', event);
  this.name = event.data.nombre + event.data.apellido + event.data.apellido2;
  this.proyecto = event.data.proyectos;
  this.uid = event.data.id;
  this.tel = event.data.tel
  this.email = event.data.email;
  this.servicio=event.data.servicio


this.butonrowclicked = true;
}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.clientes.modClientes(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
  
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.addbutton = false;
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: '' 
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }



  
}
/******************************************************* */




@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})

export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {  
  armtest: any;
  client: any;
  fecha: any;
  selectedValue: any;
  username: any;
 // proyectos: any;
 choosedfasename: any
  servicios: any;
  Clientes2: any ;
  numero_presup: any;
  choosedservice: any;
  uid: any;
  tel: any;
  email: any;
  servicename: any;
  fechafalta: any;
  triger: any;
  servicedata: any;
choosedfase: any = 0;
filetype: any = 0;
selection: any;
fases: any;
form: FormGroup;
uploadResponse;
 // @Input() sideBar: VentasComponent; 
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */
  public bankCtrl2: FormControl = new FormControl();

  public bankFilterCtrl2: FormControl = new FormControl();
  public filteredBanks2: ReplaySubject<Bank2[]> = new ReplaySubject<Bank2[]>(1);
  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.form = this.formBuilder.group({
      avatar: [''],
      pdf: [''],
      pdf_final: []
    });
    
    this.selection = "1";
    this.presupuestos.getlastid().subscribe(res2 => {
    console.log(res2.presupuestos2[0].numero)
    let presunum = res2.presupuestos2[0].numero
    this.numero_presup = parseInt(presunum, 10)
    this.numero_presup = this.numero_presup + 1
    console.log(this.numero_presup)
    })
    this.presupuestos.getServicios()
    .subscribe(res => {
this.servicios = res.servicios;
console.log(this.servicios)
this.servicios.push({id: 3, nombre: "Elegir servicio", plazo: 0, precio: 0});
console.log(this.servicios)
this.bankCtrl.setValue(this.servicios[22]);
    


    this.filteredBanks.next(this.servicios.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })
  

    // listen for search field value changes



/*     this.proyectos.getFases("Presentacion Grafica")
    .subscribe(res => {
      console.log(res)
this.fases = res.fases;
console.log(this.fases)


this.bankCtrl2.setValue(this.fases[7]);
    


    this.filteredBanks2.next(this.fases.slice());
    this.bankFilterCtrl2.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks2();
    });
    }) */
    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }



    private setInitialValue2() {
      this.filteredBanks2
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank2, b: Bank2) => a.id === b.id;
         
        });
    }

    private filterBanks2() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl2.value;
      if (!search) {
        this.filteredBanks2.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks2.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }

  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>, private formBuilder: FormBuilder, private proyectos: TempproService,  private presupuestos: PresupuestosService, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.client = data.cliente
      this.selectedValue = data.proyecto
    //  this.uid = data.uid
      this.fecha = data.fecha
      this.tel = data.tel
      this.email = data.email
      console.log(data)
    }

    saveData(bankCtrl){

      this.triger = 0;
          console.log(bankCtrl.value);
          this.servicename = bankCtrl.value.nombre;
          this.choosedservice = bankCtrl.value.id;
          console.log(this.choosedservice)
            this.servicedata = bankCtrl.value;
            this.proyectos.getFases(this.servicename)
            .subscribe(res => {
              console.log(res)
        this.fases = res.fases;
        console.log(this.fases)
        // this.servicios.push({id: 3, nombre: "Elegir servicio", plazo: 0, precio: 0});
        
        this.bankCtrl2.setValue(this.fases[7]);
            
        
        
            this.filteredBanks2.next(this.fases.slice());
            this.bankFilterCtrl2.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterBanks2();
            });
            })




    }
    saveData1(bankCtrl){
this.choosedfase = bankCtrl.value.id
this.choosedfasename = bankCtrl.value.fasename
          console.log(bankCtrl.value);

    }


    onFileSelect(event) {
      this.filetype = 1
      console.log(event)
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('avatar').setValue(file);
      }
    }
    onFileSelect2(event) {
      this.filetype = 2
      console.log(event)
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('pdf').setValue(file);
      }
    }
    onFileSelect3(event) {
      this.filetype = 3
      console.log(event)
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('pdf_final').setValue(file);
      }
    }
  
    onSubmit(client, selectedValue, descrip, fecha) {
      console.log(client)
      console.log(selectedValue)
      console.log(descrip)
      console.log(this.choosedfase)
      console.log(this.servicename)
      console.log(fecha)
      

      const formData = new FormData();
      if (this.filetype === 1){
      formData.append('avatar', this.form.get('avatar').value);
      }
      else if (this.filetype === 2){
        formData.append('pdf', this.form.get('pdf').value);
      }
      else if (this.filetype === 3){
        formData.append('pdf_final', this.form.get('pdf_final').value);
      }
 
      this.proyectos.uploadFile(formData).subscribe(
        (res) => {
          this.uploadResponse = res;
            console.log(res);
            if(this.uploadResponse.status=="error"){
              this.filetype=4
              this.uploadResponse.url="test"
            }
            console.log(this.filetype)
            console.log(this.uploadResponse.url)

            this.proyectos.addEstadoProyecto(client, selectedValue, descrip, fecha,  this.choosedfase, this.servicename, this.uploadResponse.url, this.filetype)
            .subscribe(res => {
              console.log(res);

              if (res.response.status === 1){
  
                  this.dialogRef.close();

              }
      
            })

        },
        (err) => {  
          console.log(err);
        }
      );
    }
     

      saveDataTotal( client, selectedValue, descrip, fecha){
   console.log(selectedValue)
   if (fecha === undefined || fecha === ""){
    alert("Te falta la fecha ");
   }
       else if (this.choosedservice === undefined || this.choosedservice === ""){
    alert("Te falta el servicio ");
   }

   else if (client === undefined || client === ""){
    alert("Te falta el cliente ");
   }
   else if (selectedValue === undefined || selectedValue === ""){
    alert("Te falta el proyecto ");
   }
   else{    



var datefecha = new Date(); 
console.log(datefecha);
var dd = datefecha.getDate();
var mm = datefecha.getMonth() + 1; 
var yyyy = datefecha.getFullYear(); 
console.log(dd);
console.log(mm);
console.log(yyyy);
var datefecha1 = dd + '/' + mm + '/' + yyyy; 
console.log(datefecha1);
var datefecha2 = datefecha1.toLocaleString();
        console.log(datefecha2);

        console.log(this.selection)
        console.log(descrip);
  
        console.log(this.servicedata);
        
 
        console.log(this.servicedata);

this.onSubmit(client, selectedValue, descrip, datefecha2)


    }
    }
}







/********************************************** */


@Component({
  selector: 'fuse-dialog-data-example-dialog2',
  templateUrl: 'dialog-data-example-dialog2.html',
})

export class DialogDataExampleDialog2Component implements OnInit, OnDestroy, AfterViewInit {  
  armtest: any;
  client: any;
  fecha: any;
  selectedValue: any;
  username: any;
 // proyectos: any;
 choosedfasename: any
  servicios: any;
  servicio: any;
  Clientes2: any ;
  numero_presup: any;
  choosedservice: any;
  uid: any;
  tel: any;
  email: any;
  result: any;
  servicename: any;
  fechafalta: any;
  triger: any;
  servicedata: any;
choosedfase: any = 0;
filetype: any = 0;
selection: any;
fases: any;
form: FormGroup;
uploadResponse;
 // @Input() sideBar: VentasComponent; 
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */
  public bankCtrl2: FormControl = new FormControl();

  public bankFilterCtrl2: FormControl = new FormControl();
  public filteredBanks2: ReplaySubject<Bank2[]> = new ReplaySubject<Bank2[]>(1);
  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.form = this.formBuilder.group({
      avatar: [''],
      pdf: [''],
      pdf_final: []
    });
    
    this.selection = "1";
    this.presupuestos.getlastid().subscribe(res2 => {
    console.log(res2.presupuestos2[0].numero)
    let presunum = res2.presupuestos2[0].numero
    this.numero_presup = parseInt(presunum, 10)
    this.numero_presup = this.numero_presup + 1
    console.log(this.numero_presup)
    })
    this.presupuestos.getServicios()
    .subscribe(res => {
this.servicios = res.servicios;
console.log(this.servicios)
this.servicios.push({id: 3, nombre: "Elegir servicio", plazo: 0, precio: 0});
console.log(this.servicios)
this.bankCtrl.setValue(this.servicios[22]);
    


    this.filteredBanks.next(this.servicios.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })
  

    // listen for search field value changes




    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }



    private setInitialValue2() {
      this.filteredBanks2
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank2, b: Bank2) => a.id === b.id;
         
        });
    }

    private filterBanks2() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl2.value;
      if (!search) {
        this.filteredBanks2.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks2.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }

  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialog2Component>, private formBuilder: FormBuilder, private proyectos: TempproService,  private presupuestos: PresupuestosService, @Inject(MAT_DIALOG_DATA) public data: any) {
this.servicio = data.servicio
      this.uid = data.uid

      console.log(data)

      this.proyectos.getProyectosbyID(this.uid).subscribe(res => {
console.log(res)
this.result = res.proyectos[0]


this.proyectos.getFases(this.servicio)
.subscribe(res2 => {
  console.log(res2)
this.fases = res2.fases;
console.log(this.fases)


this.bankCtrl2.setValue(this.fases[this.result.fase - 1]);
console.log(this.bankCtrl2)



this.filteredBanks2.next(this.fases.slice());
this.bankFilterCtrl2.valueChanges
.pipe(takeUntil(this._onDestroy))
.subscribe(() => {
  this.filterBanks2();
});
})





      })

    }

    saveData(bankCtrl){

/*       this.triger = 0;
          console.log(bankCtrl.value);
          this.servicename = bankCtrl.value.nombre;
          this.choosedservice = bankCtrl.value.id;
          console.log(this.choosedservice)
            this.servicedata = bankCtrl.value; */
          
            this.proyectos.getFases(bankCtrl)
            .subscribe(res2 => {
              console.log(res2)
            this.fases = res2.fases;
            console.log(this.fases)
            
            
            this.bankCtrl2.setValue(this.fases[this.result.fase - 1]);
            console.log(this.bankCtrl2)
            
            
            
            this.filteredBanks2.next(this.fases.slice());
            this.bankFilterCtrl2.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterBanks2();
            });
            })



    }
    saveData1(bankCtrl){
this.choosedfase = bankCtrl.value.id
this.choosedfasename = bankCtrl.value.fasename
          console.log(bankCtrl.value);

    }


    onFileSelect(event) {
      this.filetype = 1
      console.log(event)
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('avatar').setValue(file);
      }
    }
    onFileSelect2(event) {
      this.filetype = 2
      console.log(event)
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('pdf').setValue(file);
      }
    }
    onFileSelect3(event) {
      this.filetype = 3
      console.log(event)
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('pdf_final').setValue(file);
      }
    }
  
    onSubmit() {

      console.log(this.choosedfase)
      console.log(this.servicename)

      

      const formData = new FormData();
      if (this.filetype === 1){
      formData.append('avatar', this.form.get('avatar').value);
      }
      else if (this.filetype === 2){
        formData.append('pdf', this.form.get('pdf').value);
      }
      else if (this.filetype === 3){
        formData.append('pdf_final', this.form.get('pdf_final').value);
      }
      this.proyectos.uploadFile(formData).subscribe(
        (res) => {
          this.uploadResponse = res;
            console.log(res);
if (this.uploadResponse.status == "error"){
  console.log("ok")
  this.filetype = 4
  this.uploadResponse.url = "undefined"

}

  this.proyectos.updateEstadoProyecto(this.uid,  this.choosedfase, this.uploadResponse.url, this.filetype, this.result.aviso)
  .subscribe(res => {
    console.log(res);

    if (res.response.status === 1){

        this.dialogRef.close();

    }

  })



        },
        (err) => {  
          console.log(err);
        }
      );
    }
     

      saveDataTotal(){
 



var datefecha = new Date(); 
console.log(datefecha);
var dd = datefecha.getDate();
var mm = datefecha.getMonth() + 1; 
var yyyy = datefecha.getFullYear(); 
console.log(dd);
console.log(mm);
console.log(yyyy);
var datefecha1 = dd + '/' + mm + '/' + yyyy; 
console.log(datefecha1);
var datefecha2 = datefecha1.toLocaleString();
        console.log(datefecha2);

        console.log(this.selection)
        
  
       
        
 
        console.log(this.servicedata);

this.onSubmit()


    
    }
}
