import { Component, ViewChild, OnInit, RendererStyleFlags2 } from '@angular/core';
import {ServiciosService} from './servicios.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'fuse-app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(private servicios: ServiciosService,  private router: Router,
    private loginService1: LoginService,  public dialog: MatDialog) { 

    this.editType = 'fullRow';
    this.rowSelection = 'single';
  }

  ngOnInit() {
    this.trigger = false;
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getServicios();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }
    
  }
  getServicios(){
    this.columnDefs = [];
    this.servicios.getServicios()
    .subscribe(res => {
      this.rowData = res.servicios;
      console.log(res);

        for (let key in res.servicios[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', hide: true, rowDrag: true,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }        else  if (key === 'codigo_postal') {
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter' };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    



      });
  }


  saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.servicios.addServicios(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }


  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.servicios.remServicios(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onRowClicked(event: any) { console.log('row', event);

this.butonrowclicked = true;
}
onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.servicios.modServicios(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
showHide(e) {
  // var checked = e.target.checked;
  console.log(e.source.id);
 this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
}


cancelrow(){
  this.gridApi.forEachNode(function(node) {
    if (node.rowIndex === 0) {
      node.setSelected(true);
      var selectedData = node.data;
      console.log(selectedData);
     
  // dataforcancel(selectedData);
    }
  });
  this.cancelrow2();

  }
  cancelrow2()
  {
    var selectedData = this.gridApi.getSelectedRows();
    var res = this.gridApi.updateRowData({ remove: selectedData });
    this.addbutton = false;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
