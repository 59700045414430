import { ProyectosComponent } from '../proyectos/proyectos.component';
import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
import {TempproHistoryService} from './temp_pro_history.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import {PresupuestosService} from '../presupuestos/presupuestos.service';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import {AnadirpagocomponentComponent} from '../addpago/anadirpagocomponent/anadirpagocomponent.component'
import { MatSelect, VERSION } from '@angular/material';
// import {Anadirpagocomponent2Component} from '../anadirpagocomponent2/anadirpagocomponent2.component'
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
interface Bank2 {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './temp_pro_history.component.html',
  styleUrls  : ['./temp_pro_history.component.scss'],
})
export class TempproHistoryComponent implements OnInit  {
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  name: any;
  proyecto: any;
  curfecha: any;
  uid: any;
  tel: any;
email: any;
  armtest: any;
  clientepresup: any;
  proyectopresup: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  sidebar: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  private components;
  form: FormGroup;
  uploadResponse;

/** control for the selected bank */














  
  constructor(private clientes: TempproHistoryService,  private router: Router, private formBuilder: FormBuilder,
    private loginService1: LoginService,   public dialog: MatDialog) {
 
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';

  }
  ngOnInit() {
    // set initial selection
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getClientes2();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }

    this.trigger = false;
  // this.getClientes2();

  }





/** list of banks filtered by search keyword */















  getClientes2(){
    this.columnDefs = [];

    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }







    this.clientes.getProyectosLog()
    .subscribe(res => {
      this.rowData = res.proyectos;
      console.log(res);

        for (let key in res.proyectos[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  hide: true, filter: 'agTextColumnFilter', rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }        
      else  if (key === 'fase') {
        this.dat = {headerName: key, field: key, hide: true, filter: 'agTextColumnFilter',  width: 100, editable: true,    cellEditor: "datePicker" };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }

  else  if (key === 'codigo') {
    this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, editable: false};
    console.log('      key:',  key);

    this.columnDefs.push(this.dat);
     console.log(this.columnDefs);

}
  
  else  if (key === 'fasename') {
    this.dat = {headerName: 'Fase', field: key,  filter: 'agTextColumnFilter',   editable: false  };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'nombre_cliente') {
  this.dat = {headerName: 'Cliente', field: key,  filter: 'agTextColumnFilter',  width: 100, editable: false,
  filterParams: {
    filterOptions: ['contains', 'notContains'],
    textFormatter: function(r) {
      if (r == null){ return null}
      var r = r.toLowerCase();
      // r = r.replace(new RegExp("\\s", 'g'),"");
      r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
      r = r.replace(new RegExp('æ', 'g'), 'ae');
      r = r.replace(new RegExp('ç', 'g'), 'c');
      r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
      r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
      r = r.replace(new RegExp('ñ', 'g'), 'n');
      r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
      r = r.replace(new RegExp('œ', 'g'), 'oe');
      r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
      r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
      return r;
    },
    debounceMs: 0,
    caseSensitive: false,
   // suppressAndOrCondition: true,
  },  };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
  
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable: false,
          filterParams: {
            filterOptions: ['contains', 'notContains'],
            textFormatter: function(r) {
              if (r == null){ return null}
              var r = r.toLowerCase();
              // r = r.replace(new RegExp("\\s", 'g'),"");
              r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
              r = r.replace(new RegExp('æ', 'g'), 'ae');
              r = r.replace(new RegExp('ç', 'g'), 'c');
              r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
              r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
              r = r.replace(new RegExp('ñ', 'g'), 'n');
              r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
              r = r.replace(new RegExp('œ', 'g'), 'oe');
              r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
              r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
              return r;
            },
            debounceMs: 0,
            caseSensitive: false,
           // suppressAndOrCondition: true,
          },
          
          };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    



      });
  }
/*  
  setPriceOnToyota() {   
    var rowNode = this.gridApi.getRowNode("aa");
    var newPrice = Math.floor(Math.random() * 100000);
    rowNode.setDataValue("price", newPrice);
  } */

  saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.clientes.addClientes(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }

  addItems() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({ add: newItems });
    printResult(res);
  }

  addItemsAtIndex() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({
      add: newItems,
      addIndex: 2
    });
    printResult(res);
  }

  updateItems() {
    var itemsToUpdate = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    printResult(res);
  }

  onInsertRowAt2() {
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: 0
    });
    printResult(res);
  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.clientes.remClientes(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onCellDoubleClicked(event1: any) { console.log('row', event1); 

if (event1.colDef.field === 'fecha_contacto' && this.addbutton === false){

  var armindex = this.gridApi.getFocusedCell();
  console.log(armindex);
  // this.gridApi.stopEditing();
 // this.openDialog(armindex.rowIndex);

}

}

testfunc(){

 var armpordz = this.gridApi.getRowNode(0);
 console.log(armpordz);
}


  onRowClicked(event: any) {
     console.log('row', event);
  this.name = event.data.nombre + event.data.apellido + event.data.apellido2;
  this.proyecto = event.data.proyectos;
  this.uid = event.data.id;
  this.tel = event.data.tel
  this.email = event.data.email;


this.butonrowclicked = true;
}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.clientes.modClientes(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
  
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.addbutton = false;
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: '' 
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }



  
}
/******************************************************* */

