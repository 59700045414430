import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {PagosrService} from './pagos_r.service';
import {Anadirpagocomponent2Component} from '../anadirpagocomponent2/anadirpagocomponent2.component'
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MatSelect, VERSION } from '@angular/material';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-app-pagosr',
  templateUrl: './pagos_r.component.html',
  styleUrls: ['./pagos_r.component.scss']
})
export class PagosrComponent implements OnInit {
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  armtest: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  sidebar: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  private components;


/** control for the selected bank */














  
  constructor(private pagos_r: PagosrService,  private router: Router,
    private loginService1: LoginService,  public dialog: MatDialog) {
 
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';
  }
  ngOnInit() {
    // set initial selection
   

    this.trigger = false;
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getPagos_r();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }
  
  }





/** list of banks filtered by search keyword */














openDialog(rowindex): void {
  const dialogRef = this.dialog.open(Anadirpagocomponent2Component, {
    width: '600px',
    height: '600px',
    
  });

  dialogRef.afterClosed().subscribe(result => {
    var newItem =  {
      cliente: result.cliente,
      proyecto: result.proyecto,
      servicio: result.servicio,
      importe: result.importe,
      concepto: result.concepto,
      fecha: result.fecha
    };
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0 });

  });
}
  getPagos_r(){
    this.columnDefs = [];

    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }







    this.pagos_r.getPagos_r()
    .subscribe(res => {
      this.rowData = res.pagos;
      console.log(res);

        for (let key in res.pagos[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  hide: true,  filter: 'agTextColumnFilter', rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }    
        else  if (key === 'cliente') {
          this.dat = {headerName: key, field: key, editable: false };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);
  
      }
      else  if (key === 'proyecto') {
        this.dat = {headerName: key, field: key, editable: false};
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
    else  if (key === 'servicio') {
      this.dat = {headerName: key, field: key, editable: false };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
  else  if (key === 'importe') {
    this.dat = {headerName: key, field: key, editable: false, width: 100 };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
  else  if (key === 'concepto') {
    this.dat = {headerName: key, field: key, editable: false};
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'fecha') {
  this.dat = {headerName: key, field: key, editable: false, width: 100};
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
      else  if (key === 'timestam') {
        this.dat = {headerName: key, field: key, hide: true,  filter: 'agTextColumnFilter',  width: 100 };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }

        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    



      });
  }
/*  
  setPriceOnToyota() {   
    var rowNode = this.gridApi.getRowNode("aa");
    var newPrice = Math.floor(Math.random() * 100000);
    rowNode.setDataValue("price", newPrice);
  } */

/*   saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.pagos_r.addPagos(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  } */

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }

  addItems() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({ add: newItems });
    printResult(res);
  }

  addItemsAtIndex() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({
      add: newItems,
      addIndex: 2
    });
    printResult(res);
  }

  updateItems() {
    var itemsToUpdate = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    printResult(res);
  }

  onInsertRowAt2() {
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: 0
    });
    printResult(res);
  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.pagos_r.remClientes(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onCellDoubleClicked(event1: any) { console.log('row', event1); 

if (event1.colDef.field === 'fecha_contacto' && this.addbutton === false){

  var armindex = this.gridApi.getFocusedCell();
  console.log(armindex);
   this.gridApi.stopEditing();
  this.openDialog(armindex.rowIndex);

}

}

testfunc(){

 var armpordz = this.gridApi.getRowNode(0);
 console.log(armpordz);
}


  onRowClicked(event: any) { console.log('row', event);
  
this.butonrowclicked = true;
}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.pagos_r.modClientes(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
  
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.addbutton = false;
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: '' 
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {  
  armtest: any;
  username: any;
  proyectos: any;
  servicios: any;
  Clientes2: any ;
  selectedValue: any ;
  selectedValue2: any ;
  importe: any ;
  concepto: any ;
  fecha: any ;
  choosedcliente: any;
  clientename: any;
  triger: any;
  clientedata: any;
  @Input() sideBar: PagosrComponent; 
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */

  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.triger = 0;
    this.pagos_r.getClientes()
    .subscribe(res => {
this.clientes = res.clientes2;
console.log(this.clientes)
this.bankCtrl.setValue(this.clientes[10]);
    

    // load the initial bank list
    this.filteredBanks.next(this.clientes.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.clientes) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.clientes.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.clientes.filter(clientes => clientes.nombre.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>, private pagos_r: PagosrService) {
      
    }
    
    saveData(bankCtrl){
      if (bankCtrl.value.CIF === undefined || bankCtrl.value.CIF === "undefined"){
        alert("Faltan datos de CIF");
        this.triger = 1;
      }
      else if (bankCtrl.value.email === undefined || bankCtrl.value.email === "undefined"){

        alert("Faltan datos de email");
        this.triger = 1;
      }
      else if (bankCtrl.value.direccion === undefined || bankCtrl.value.direccion === "undefined"){

        alert("Faltan datos de direccion");
        this.triger = 1;
      }
      else if (bankCtrl.value.codigo_postal === undefined || bankCtrl.value.codigo_postal === "undefined"){

        alert("Faltan datos de codigo postal");
        this.triger = 1;
      }
      else if (bankCtrl.value.ciudad === undefined || bankCtrl.value.ciudad === "undefined"){

        alert("Faltan datos de ciudad");
        this.triger = 1;
      }
else{
  this.triger = 0;
      console.log(bankCtrl.value);
      this.clientename = bankCtrl.value.nombre;
      this.choosedcliente = bankCtrl.value.id;
        this.clientedata = bankCtrl.value;
      // this.dialogRef.close({id_cliente: bankCtrl.value.id, nombre: bankCtrl.value.nombre});
      this.pagos_r.getProyectosForR(bankCtrl.value.id)
      .subscribe(res => {
        
  this.proyectos = res.proyectos;
  console.log(this.proyectos)

      })
    }
      }
      saveData2(value){

        this.pagos_r.getServicios(value.id)
        .subscribe(res => {
    this.servicios = res.servicios;
    console.log(this.servicios)
  
        })

        console.log(value);
      }

     

      saveDataTotal(fecha, concepto, importe, selectedValue2, selectedValue){
        if (this.choosedcliente === undefined ||  fecha === undefined  || concepto === undefined || importe === undefined || selectedValue2 === undefined || selectedValue === undefined){
alert("rellene todos los campos")
        }


        else{
var datefecha = new Date(fecha._d); 
var datefecha2 = datefecha.toLocaleString();
        console.log(datefecha2);
        console.log(concepto);
        console.log(importe);
        console.log(selectedValue2);
        console.log(selectedValue);
        console.log(this.clientedata);
        this.pagos_r.addPagos(this.choosedcliente, datefecha2, concepto, importe, selectedValue2.id, selectedValue.id)
      .subscribe(res => {
        console.log(res);



        if (res.response.status === 1){
          this.pagos_r.sendEmail(this.clientedata, datefecha2, concepto, importe, selectedValue2.nombre, selectedValue.nombre)
          .subscribe(res2 => {
            console.log(res2);
            var fullDate = datefecha;
            var twoDigitMonth = fullDate.getMonth() + "";
            if (twoDigitMonth.length === 1){
                twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length === 1){
                twoDigitDate = "0" + twoDigitDate;
            }
            
            
            
            var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; console.log(currentDate);
            this.dialogRef.close({cliente: this.clientename, proyecto: selectedValue.nombre, servicio: selectedValue2.nombre, importe: importe, concepto: concepto, fecha: currentDate });
          })

        }

      })
      }
    }

}

