import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { PresupuestosService } from './presupuestos.service';
import { PresupuestosComponent, DialogDataExampleDialogComponent } from './presupuestos.component';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatRadioModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ButtonRendererComponent } from './renderer.component';
import { NgxLoadingModule } from 'ngx-loading';
const routes = [
    {
        path     : 'presupuestos',
        component: PresupuestosComponent
    }
];

@NgModule({
    declarations: [
        PresupuestosComponent,
        DialogDataExampleDialogComponent,
        ButtonRendererComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        SweetAlert2Module,
        NgxLoadingModule,
        MatIconModule,
        TranslateModule,
        AgGridModule.withComponents([]),
        FuseSharedModule,
        HttpClientModule, HttpModule,
        FuseConfirmDialogModule,
        AgGridModule.withComponents([ButtonRendererComponent]),
        
        NgxMatSelectSearchModule,
     
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule
   
    
    ],
    exports     : [
        PresupuestosComponent
    ],
    providers: [PresupuestosService],
    entryComponents: [DialogDataExampleDialogComponent],

 
})

export class PresupuestosModule
{
}
