import { Component, OnInit,  OnDestroy, AfterViewInit, Input, ViewChild } from '@angular/core';
import { ServiceEnLineService } from './ServiceEnLine.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MatSelect, VERSION } from '@angular/material';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-app-serviceenline',
  templateUrl: './ServiceEnLine.component.html',
  styleUrls: ['./ServiceEnLine.component.scss']
})
export class ServiceEnLineComponent implements OnInit {
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  carMappings_R: any;
  carMappings_R2: any;
  personastoadd: any;
  
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;  private components;
  constructor(private ServiceEnLine: ServiceEnLineService,  private router: Router,
    private loginService1: LoginService,   public dialog: MatDialog) { 
  this.editType = 'fullRow';
  this.rowSelection = 'single';
}
  ngOnInit() {
    this.trigger = false;
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getPersonas();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }
    
  }

  getPersonas(){

    this.ServiceEnLine.getRRHH2().subscribe(res1 => {
      console.log(res1);
         var carMappings2 = res1.rrhh;
       var result = {};
          for (var i = 0; i < carMappings2.length; i++) {
            result[carMappings2[i].id] = carMappings2[i].nombre;
          }
          this.carMappings_R = result;
      console.log(result);
    // this.getSectores(this.carMappings_R);


    var carMappings1 = [{ id: 0, nombre: "NO"}, {id: 1,  nombre: "SI"}];
    var result1 = {};
       for (var x = 0; x < carMappings1.length; x++) {
         result1[carMappings1[x].id] = carMappings1[x].nombre;
       }
       this.carMappings_R2 = result1;
   console.log(result1);



    this.getServiceEnLine(this.carMappings_R, this.carMappings_R2)
          });
  }
  openDialog2(rowindex, dataid): void {
    /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
     console.log(dataformod2);
     this.proyectos.modProyectos(dataformod2.data)
     .subscribe(res => {
         
       console.log(res);
           }); */
    
 
     const dialogRef2 = this.dialog.open(DialogDataExampleDialog5Component, {
       width: '450px',
       height: '450px',
       
     });
   
     dialogRef2.afterClosed().subscribe(result => {
       var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
       console.log(result);

       var names = result.map(function(item) {
        return item['nombre'];
      });
   

       rowNode.setDataValue('quien_hace',  names);
      // this.armtest = result.age;
      var dataformod = this.gridApi.getRowNode(rowindex);
      console.log(dataformod);
      console.log(dataformod.data.id);
     
      this.personastoadd = names;
/*       if (dataid.id){
      this.proyectos.modProyectos2(dataformod.data.id, result.id_cliente)
      .subscribe(res => {
          
        console.log(res);
            });
           }
           else{
 
 this.clientetoadd = result.id_cliente;
 console.log(this.clientetoadd)
           } */
     });
   }


  getServiceEnLine(data2, data1){




    function extractValues(mappings) {
      return Object.keys(mappings);
    }
    function lookupValue(mappings, key) {
      return mappings[key];
    }
    function lookupKey(mappings, name) {
      for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
            return key;
          }
        }
      }
    }



    this.columnDefs = [];
    this.ServiceEnLine.getServiceEnLine()
    .subscribe(res => {
      this.rowData = res.ServiceEnLine;
      console.log(res);

        for (let key in res.ServiceEnLine[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  hide: true  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }        else  if (key === 'cliente') {
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : false };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }
           else  if (key === 'proyecto') {
      this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : false };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
  else  if (key === 'servicio') {
    this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : false };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'quien_hace') {
  this.dat = {headerName: key, field: key, editable: false,  filter: 'agTextColumnFilter' };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'fecha_entrega') {
  this.dat = {headerName: key, field: key, editable: true, width: 130,  filter: 'agTextColumnFilter', cellEditor: "datePicker" };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'fecha_inicio') {
  this.dat = {headerName: key, field: key, editable: false, width: 110,  filter: 'agTextColumnFilter', cellEditor: "datePicker" };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}

else  if (key === 'fecha_prev_entrega') {
  this.dat = {headerName: key, field: key, editable: false, width: 155,  filter: 'agTextColumnFilter', cellEditor: "datePicker" };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'pres_a_disponer') {
  this.dat = {headerName: key, field: key, editable: true, width: 140,  filter: 'agTextColumnFilter' };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'gastado') {
  this.dat = {headerName: key, field: key, editable: true, width: 100,  filter: 'agTextColumnFilter' };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'en_proceso') {
  this.dat = {headerName: key, field: key,  editable: true,  
  cellClassRules: {
    'rag-red': function(params) { return params.value === 'Carlos'},
    'rag-orange': function(params) { return params.value === 'Roberto'},
    'rag-green': function(params) { return params.value === 'Juan'},
    'rag-yellow': function(params) { return params.value === 'Arman'},
    'rag-blue': function(params) { return params.value === 'Jose'},
    'rag-beige': function(params) { return params.value === 'David'},
    'rag-pink': function(params) { return params.value === 'Sara'},
    'rag-lightgrenn': function(params) { return params.value === 'lightgrenn'},
    'rag-purple': function(params) { return params.value === 'purple'},
    'rag-red2': function(params) { return params.value === 'carlos'},
    'rag-orange2': function(params) { return params.value === 'roberto'},
    'rag-green2': function(params) { return params.value === 'juan'},
    'rag-yellow2': function(params) { return params.value === 'arman'},
    'rag-blue2': function(params) { return params.value === 'jose'},
    'rag-beige2': function(params) { return params.value === 'david'},
    'rag-pink2': function(params) { return params.value === 'sara'}

  
},
cellRenderer: function(params) {
    return '<span class="rag-element">' + params.value + '</span>';
}
/* 
 */


/*   cellEditorParams: {values: extractValues(data2) },
  valueFormatter: function(params) {
    return lookupValue(data2, params.value);
  },
  valueParser: function(params) {
    return lookupKey(data2, params.newValue);
  }, */





};
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'cerrado') {
  this.dat = {headerName: key, field: key,  editable: true,  cellEditor: "agSelectCellEditor", width: 90, 
  cellEditorParams: {values: extractValues(data1) },
  valueFormatter: function(params) {
    return lookupValue(data1, params.value);
  },
  valueParser: function(params) {
    return lookupKey(data1, params.newValue);
  }
};
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
   console.log(this.columnDefs);
  
  //  var selectedData = this.gridApi.getSelectedRows();
   // this.gridApi.updateRowData({ remove: selectedData });
   
   // this.gridApi.refreshCells();
  

}
else  if (key === 'pagado') {
  this.dat = {headerName: key, field: key,  editable: true,  cellEditor: "agSelectCellEditor",  width: 90, 
  cellEditorParams: {values: extractValues(data1) },
  valueFormatter: function(params) {
    return lookupValue(data1, params.value);
  },
  valueParser: function(params) {
    return lookupKey(data1, params.newValue);
  }
};
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}

        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    



      });


      this.components = { datePicker: getDatePicker() };
      function getDatePicker() {
      
        function Datepicker() {}
        
        Datepicker.prototype.init = function(params) {
          this.eInput = document.createElement("input");
          this.eInput.value = params.value;
          $(this.eInput).datepicker({ dateFormat: "dd-mm-yy" });
        };
        Datepicker.prototype.getGui = function() {
          return this.eInput;
        };
        Datepicker.prototype.afterGuiAttached = function() {
          this.eInput.focus();
          this.eInput.select();
        };
        Datepicker.prototype.getValue = function() {
          return this.eInput.value;
        };
        Datepicker.prototype.destroy = function() {};
        Datepicker.prototype.isPopup = function() {
          return false;
        };
      
        return Datepicker;
      
      }

  }


  

  onCellDoubleClicked(event1: any) { console.log('doubleclick', event1); 
  if (event1.colDef.field === 'quien_hace' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  // this.gridApi.stopEditing();
  this.openDialog2(armindex2.rowIndex, event1.data);

}
  }




  saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.ServiceEnLine.addServiceEnLine(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }


  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = 'Yo creo que no debes eliminar un servicio desde esta tabla. Pregunta a administrador(Arman) sobre mas detalles';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
        alert("No se ha eliminado!!!!!. Parece que no me has entindo ")
            
/*             var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.ServiceEnLine.remServiceEnLine(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res); */
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onRowClicked(event: any) { console.log('row', event);

this.butonrowclicked = true;
}
onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.ServiceEnLine.modServiceEnLine(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
showHide(e) {
  // var checked = e.target.checked;
  console.log(e.source.id);
 this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
}


cancelrow(){
  this.gridApi.forEachNode(function(node) {
    if (node.rowIndex === 0) {
      node.setSelected(true);
      var selectedData = node.data;
      console.log(selectedData);
     
  // dataforcancel(selectedData);
    }
  });
  this.cancelrow2();

  }
  cancelrow2()
  {
    var selectedData = this.gridApi.getSelectedRows();
    var res = this.gridApi.updateRowData({ remove: selectedData });
    this.addbutton = false;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}


@Component({
  selector: 'fuse-dialog-data-example-dialog5',
  templateUrl: 'dialog-data-example-dialog5.html',
})
export class DialogDataExampleDialog5Component implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  Clientes2: any ;
  @Input() sideBar: ServiceEnLineComponent;
  
 
  private pers_enc: any; 
    
  /** control for the selected bank for multi-selection */
  public bankMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public bankMultiFilterCtrl: FormControl = new FormControl();

  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  public filteredBanksMulti: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('multiSelect') multiSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.ServiceEnLine.getRRHH2()
    .subscribe(res => {
this.pers_enc = res.rrhh;
console.log(this.pers_enc)
/* this.bankMultiCtrl.setValue([this.pers_enc[1], this.pers_enc[2], this.pers_enc[3]]); */
    

    // load the initial bank list
    
    this.filteredBanksMulti.next(this.pers_enc.slice());
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });
    })
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
         
          this.multiSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
        });
    }



    private filterBanksMulti() {
      if (!this.pers_enc) {
        return;
      }
      // get the search keyword
      let search = this.bankMultiFilterCtrl.value;
      if (!search) {
        this.filteredBanksMulti.next(this.pers_enc.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.filteredBanksMulti.next(
        this.pers_enc.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
      );
    }




  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef2: MatDialogRef<DialogDataExampleDialog5Component>, private ServiceEnLine: ServiceEnLineService) {}
    
    saveData2(bankCtrl){
      console.log(bankCtrl.value);
      this.dialogRef2.close(bankCtrl.value);
      }

}
