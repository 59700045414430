import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { VentasService } from './ventas.service';
import { VentasComponent, DialogDataExampleDialogComponent, Dialog2DataExampleDialogComponent } from './ventas.component';
import {AnadirpagocomponentComponent} from '../addpago/anadirpagocomponent/anadirpagocomponent.component'
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatRadioModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseConfirmDialog2Module} from '@fuse/components';
import { FuseConfirmDialog3Module} from '@fuse/components';
import { FuseConfirmDialog4Module} from '@fuse/components';


import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxLoadingModule } from 'ngx-loading';
const routes = [
    {
        path     : 'ventas',
        component: VentasComponent
    }
];
registerLocaleData(localeEs);
@NgModule({
    declarations: [
        VentasComponent,
        DialogDataExampleDialogComponent,
        Dialog2DataExampleDialogComponent,

        AnadirpagocomponentComponent

    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        SweetAlert2Module,
        MatIconModule,
        NgxLoadingModule,
        TranslateModule,
        AgGridModule.withComponents([]),
        FuseSharedModule,
        HttpClientModule, HttpModule,
        FuseConfirmDialogModule,
        FuseConfirmDialog2Module,
        FuseConfirmDialog3Module,
        FuseConfirmDialog4Module,



        
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        MatDatepickerModule,
        
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule
   
    
    ],
    exports     : [
        VentasComponent
       
    ],
    providers: [VentasService],
    entryComponents: [DialogDataExampleDialogComponent, Dialog2DataExampleDialogComponent, AnadirpagocomponentComponent],

 
})

export class VentasModule
{
}
