import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { TempproService } from './temp_pro.service';
import { TempproComponent, DialogDataExampleDialogComponent, DialogDataExampleDialog2Component } from './temp_pro.component';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
// import {Anadirpagocomponent2Component} from '../anadirpagocomponent2/anadirpagocomponent2.component'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
const routes = [
    {
        path     : 'temp_pro',
        component: TempproComponent
    }
];

@NgModule({
    declarations: [
        TempproComponent,
        DialogDataExampleDialogComponent,
        DialogDataExampleDialog2Component
      //  Anadirpagocomponent2Component
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        SweetAlert2Module,
        MatIconModule,
        TranslateModule,
        AgGridModule.withComponents([]),
        FuseSharedModule,
        HttpClientModule, HttpModule,
        FuseConfirmDialogModule,
        MatDatepickerModule,
        
        NgxMatSelectSearchModule,
        MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule
    
    ],
    exports     : [
        TempproComponent
    ],
    providers: [TempproService],
    entryComponents: [DialogDataExampleDialogComponent, DialogDataExampleDialog2Component],

 
})

export class TempproModule
{
}
