import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ProyectosComponent } from './proyectos.component';

import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { ProyectosService } from './proyectos.service';
import { ProyectosComponent, DialogDataExampleDialogComponent, DialogDataExampleDialog2Component, DialogDataExampleDialog3Component, DialogDataExampleDialog4Component, DialogDataExampleDialog5Component } from './proyectos.component';

import { NgxLoadingModule } from 'ngx-loading';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatSelectModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
const routes = [
    {
        path     : 'proyectos',
        component: ProyectosComponent
    }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    SweetAlert2Module,
    MatIconModule,
    TranslateModule,
    NgxLoadingModule,
    AgGridModule.withComponents([]),
    FuseSharedModule,
    HttpClientModule, HttpModule,
    FuseConfirmDialogModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
MatToolbarModule,
MatFormFieldModule,
NgMultiSelectDropDownModule.forRoot()
  ],
  exports     : [
    ProyectosComponent
],
  declarations: [ProyectosComponent,  DialogDataExampleDialogComponent, DialogDataExampleDialog2Component, DialogDataExampleDialog3Component, DialogDataExampleDialog4Component, DialogDataExampleDialog5Component],
  providers: [ProyectosService],
  entryComponents: [DialogDataExampleDialogComponent, DialogDataExampleDialog2Component, DialogDataExampleDialog3Component, DialogDataExampleDialog4Component, DialogDataExampleDialog5Component],
})
export class ProyectosModule { }
