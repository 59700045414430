
import {Injectable} from '@angular/core';
// import {AuthHttp} from 'angular2-jwt';
import {Response, Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Injectable(/* {
  providedIn: 'root'
} */)
export class TempproService {
  private baseUrl = 'http://lfdi.bitronedev.com/public/';
  private SERVER_URL = 'http://miproyecto.lfdi.es';
constructor(public http: Http ) {


   }

   getClientes(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}clientes/getClientes`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  addClientes(datatosend): Observable<any> {
    console.log(datatosend);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'nombre=' + datatosend.nombre + '&apellido=' + datatosend.apellido + '&apellido2=' + datatosend.apellido2 + '&CIF=' + datatosend.CIF + '&fecha_contacto=' + datatosend.fecha_contacto + '&telefono=' + datatosend.telefono + '&proyectos=' + datatosend.proyectos + '&email=' + datatosend.email + '&direccion=' + datatosend.direccion + '&codigo_postal=' + datatosend.codigo_postal + '&ciudad=' + datatosend.ciudad + '&empresa=' + datatosend.empresa + '&comentarios=' + datatosend.comentarios ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}clientes/addClientes`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }

  getProyectosForRAll(): any {
    
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem(‘id_token’);
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
   
    let options = new RequestOptions({headers: authHeader});
    return this.http.get(`${this.baseUrl}proyectos/getProyectosJuanAll`,  options)
      .map(this.extractData)
      .catch(this.handleError);
  }




  getFases(servicio): any {
    
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem(‘id_token’);
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'servicio=' + servicio;
    let options = new RequestOptions({headers: authHeader});
    
    return this.http.post(`${this.baseUrl}proyectos/getFases`, body,  options)
      .map(this.extractData)
      .catch(this.handleError);
  }




  modClientes( datatomod ): any {
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id + '&nombre=' + datatomod.nombre +  '&apellido=' + datatomod.apellido + '&apellido2=' + datatomod.apellido2 + '&proyectos=' + datatomod.proyectos + '&fecha_contacto=' + datatomod.fecha_contacto + '&CIF=' + datatomod.CIF + '&telefono=' + datatomod.telefono + '&email=' + datatomod.email + '&direccion=' + datatomod.direccion + '&codigo_postal=' + datatomod.codigo_postal + '&ciudad=' + datatomod.ciudad + '&empresa=' + datatomod.empresa + '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}clientes/modClientes`, body,  options).map(this.extractData2).catch(this.handleError);
  }




  public uploadFile(data): any  {
    console.log(data)
    let uploadURL = `${this.SERVER_URL}/backend/upload.php`;

    return this.http.post(uploadURL, data).map(this.extractData)
    .catch(this.handleError);
  }


  addEstadoProyecto(client, selectedValue, descrip, fecha,  choosedfase, servicename, uploadResponse_name, filetype): Observable<any> {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'cliente=' + client + '&proyecto=' + selectedValue + '&aviso=' + descrip + '&fase=' + choosedfase + '&servicio=' + servicename + '&file=' + uploadResponse_name + '&fecha=' + fecha + '&filetype=' + filetype;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}proyectos/addEstadoProyecto`, body, options)
      .map(this.extractData)
      .catch(this.handleError);

  }




  updateEstadoProyecto( uid, choosedfase,  uploadResponse_name, filetype, aviso): Observable<any> {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + uid + '&fase=' + choosedfase +  '&file=' + uploadResponse_name +  '&filetype=' + filetype +  '&aviso=' + aviso;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}proyectos/updateEstadoProyecto`, body, options)
      .map(this.extractData)
      .catch(this.handleError);

  }

  getProyectosbyID(id): Observable<any> {
   console.log(id)
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + id  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}proyectos/getProyectosJuanID`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }




  remClientes(datatosend): Observable<any> {
   
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + datatosend  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}clientes/remClientes`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }
  /**
   * Extracting data.
   *
   * @param res
   * @returns {any|{}}
   */
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private extractData2(res: Response) {
    let body = res;
    return body || {};
  }
 /**
  * Handling errors.
  *
  * @param error
  * @returns {ErrorObservable}
  */
 private handleError(error: any) {
   let errMsg = (error.message) ? error.message :
     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
   console.log(errMsg); // log to console instead
   return Observable.throw(errMsg);
 }
}

