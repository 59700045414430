import { NgModule } from '@angular/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialog4Component } from '@fuse/components/confirm-dialog4/confirm-dialog4.component';
import { MatButtonModule, MatDialogModule, MatDatepickerModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatRadioModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
@NgModule({
    declarations: [
        FuseConfirmDialog4Component
    ],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
  
        MatIconModule,
        TranslateModule,
    
        FuseSharedModule,
        HttpClientModule, HttpModule,
        FuseConfirmDialogModule,
        
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        MatDatepickerModule,
        
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule
    ],
    entryComponents: [
        FuseConfirmDialog4Component
    ],
})
export class FuseConfirmDialog4Module
{
}
