import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagosComponent } from './pagos.component';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { PagosService } from './pagos.service';
import { NgxLoadingModule } from 'ngx-loading';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ButtonRendererComponent } from './renderer.component';
const routes = [
  {
      path     : 'pagos',
      component: PagosComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    SweetAlert2Module,
    MatIconModule,
    TranslateModule,
    NgxLoadingModule,
    AgGridModule.withComponents([]),
    FuseSharedModule,
    HttpClientModule, HttpModule,
    FuseConfirmDialogModule,
    AgGridModule.withComponents([ButtonRendererComponent]),
  ],
  exports     : [
    PagosComponent
],
  declarations: [PagosComponent, ButtonRendererComponent],
  providers: [PagosService]
})
export class PagosModule { }
