import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {LoginService} from './login.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Component({
    selector   : 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls  : ['./login.component.scss'],
    animations : fuseAnimations
})
export class FuseLoginComponent implements OnInit
{
    loginForm: FormGroup;
    loginFormErrors: any;

    constructor(
        private loginService1: LoginService,
        private fuseConfig: FuseConfigService,
        private formBuilder: FormBuilder,
        private router: Router
    )
    {
        this.fuseConfig.setConfig({
            layout: {
                navigation: 'none',
                toolbar   : 'none',
                footer    : 'none'
            }
        });

        this.loginFormErrors = {
            email   : {},
            password: {}
        };

    }

    ngOnInit()
    {
        this.loginForm = this.formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });

        if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
            console.log(localStorage)
            this.router.navigate(['/login']);
          } else {
            this.loginService1.checkToken().subscribe(rescat => {
              console.log(rescat);
              if (rescat.response){
                if (rescat.response === 1){
    
    
                  
                    this.router.navigate(['/ventas']);
              }
              else{
    
                this.router.navigate(['/login']);
              }
            }
              else{
                this.router.navigate(['/login']);
              }
    
            })
              }


    }





    onLoginFormValuesChanged()
    {
        for ( const field in this.loginFormErrors )
        {
            if ( !this.loginFormErrors.hasOwnProperty(field) )
            {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if ( control && control.dirty && !control.valid )
            {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }
login(value){


    this.loginService1.getUser(value.email, value.password).subscribe(token => {

  console.log(token);
        
        if (token.token && token !== 'undefined' && token !== 'No user found') {
  
          localStorage.setItem('id_token', token.token);
          localStorage.setItem('user_login', value.user_login);
          this.router.navigate(['/clientes']);
        } else {
          console.log(value.email);
          console.log(value.password);
       
        }
      });

    }

}
