import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import {RrhhService} from './rrhh.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { reduce } from '../../../../../node_modules/rxjs-compat/operator/reduce';
declare var jquery: any; declare var $: any;
@Component({
  selector: 'fuse-app-rrhh',
  templateUrl: './rrhh.component.html',
  styleUrls: ['./rrhh.component.scss']
})
export class RrhhComponent implements OnInit {
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  datatosend: any;
  datatomod: any;
  carMappings: any;
  newchoosedcolor: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  private components;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(private rrhh: RrhhService,  private router: Router,
    private loginService1: LoginService,   public dialog: MatDialog, ) {
    this.editType = 'fullRow';
    this.rowSelection = 'single';

   }

  ngOnInit() {
    this.trigger = false;

    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.rrhh.getRrhh_puestos().subscribe(res1 => {
              console.log(res1);
                 var carMappings1 = res1.rrhh_p;
               var result = {};
                  for (var i = 0; i < carMappings1.length; i++) {
                    result[carMappings1[i].id] = carMappings1[i].nombre;
                  }
                  this.carMappings = result;
              console.log(result);
              this.getRrhh(this.carMappings);
                  });
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }



          
 

  }

  openDialog(rowindex, data): void {
    const dialogRef = this.dialog.open(DialogDataExampleDialog2Component, {
      width: '450px',
      height: '450px',
      
    });
    dialogRef.afterClosed().subscribe(result => {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
      console.log(result);




      rowNode.setDataValue('color',  result);
     // this.armtest = result.age;
     var dataformod = this.gridApi.getRowNode(rowindex);
     console.log(dataformod);

     this.newchoosedcolor = result;
/* 
     if (dataformod.data.id && !this.addbutton){
       this.proyectos.modProyectosPersonas(dataformod.data.id, this.personas)
       .subscribe(res => {
           
         console.log(res);
             });
            } */

    });
   }
  getRrhh(data1){
    function extractValues(mappings) {
      return Object.keys(mappings);
    }
    function lookupValue(mappings, key) {
      return mappings[key];
    }
    function lookupKey(mappings, name) {
      for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
            return key;
          }
        }
      }
    }


    this.columnDefs = [];
    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }

    this.rrhh.getRrhh()



    .subscribe(res => {
      this.rowData = res.rrhh;
      console.log(res.rrhh);



        for (let key in res.rrhh[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', hide: true, rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        } 
        else  if (key === 'nombre') {
          this.dat = {headerName: key, field: key, editable: true,  filter: 'agTextColumnFilter',  width: 150 };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }
      else  if (key === 'puesto_id') {
        this.dat = {headerName: key, field: key,  editable: true, filter: 'agTextColumnFilter',  hide: true };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
      else  if (key === 'apellido') {
        this.dat = {headerName: key, field: key, editable: true,  filter: 'agTextColumnFilter',  width: 150 };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
    else  if (key === 'apellido2') {
      this.dat = {headerName: key, field: key, editable: true,  filter: 'agTextColumnFilter',  width: 150 };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
        
        else  if (key === 'carga') {
          this.dat = {headerName: key, field: key, editable: true,  filter: 'agTextColumnFilter',  width: 100 };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }
      else  if (key === 'realizado') {
        this.dat = {headerName: key, field: key, editable: true, filter: 'agTextColumnFilter',  width: 100 };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
    else  if (key === 'pendiente') {
      this.dat = {headerName: key, field: key, editable: true,  filter: 'agTextColumnFilter',  width: 100 };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }

    else  if (key === 'fecha_nas') {
      this.dat = {headerName: key, field: key,     editable: true, width: 100,   cellEditor: "datePicker"};
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
  else  if (key === 'color') {
    this.dat = {headerName: key, field: key,     editable: false,    width: 100, cellStyle: function getCellColor(params) { return {backgroundColor: params.value}} /*  cellClassRules: {
       'rag-red': function(params) { return params.value === 'red'},
      'rag-orange': function(params) { return params.value === 'orange'},
      'rag-green': function(params) { return params.value === 'green'},
      'rag-yellow': function(params) { return params.value === 'yellow'},
      'rag-blue': function(params) { return params.value === 'blue'},
      'rag-beige': function(params) { return params.value === 'beige'},
      'rag-pink': function(params) { return params.value === 'pink'},
      'rag-lightgrenn': function(params) { return params.value === 'låightgrenn'},
      'rag-purple': function(params) { return params.value === 'purple'} 
    
  },*/
/*   cellRenderer: function(params) {
      return '<span class="rag-element">' + params.value + '</span>';
  } */};
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    

}
    else  if (key === 'puesto') {
      this.dat = {headerName: key, field: key,  editable: true,  cellEditor: "agSelectCellEditor",
      cellEditorParams: {values: extractValues(data1) },
      valueFormatter: function(params) {
        return lookupValue(data1, params.value);
      },
      valueParser: function(params) {
        return lookupKey(data1, params.newValue);
      }
};
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    



      });


  }


  onCellDoubleClicked(event1: any) { console.log('doubleclick', event1); 
  if (event1.colDef.field === 'color' ){
    var armindex2 = this.gridApi.getFocusedCell();
    console.log(armindex2);
    // this.gridApi.stopEditing();
    this.openDialog(armindex2.rowIndex, event1.data);
    // var col = this.gridColumnApi.getColumn("fecha_ini");
  
    // obtain the column definition from the column
    // var colDef = col.getColDef();
    
    // update the header name
   // colDef.editable = false;
  }
  }

  saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.rrhh.addRrhh(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.rrhh.remRrhh(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onRowClicked(event: any) { console.log('row', event);

  this.butonrowclicked = true;
  }
  onRowValueChanged(event: any){console.log('row', event.data);
  
  
  
  if (isNaN(event.data.puesto_id) === false){
    event.data.puesto_id = parseInt(event.data.puesto, 10)
  }
  this.datatomod = event.data;
console.log(this.datatomod)
if (this.datatomod){
  this.rrhh.modRrhh(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
showHide(e) {
  // var checked = e.target.checked;
  console.log(e.source.id);
 this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
}


cancelrow(){
  this.gridApi.forEachNode(function(node) {
    if (node.rowIndex === 0) {
      node.setSelected(true);
      var selectedData = node.data;
      console.log(selectedData);
     
  // dataforcancel(selectedData);
    }
  });
  this.cancelrow2();

  }  
  
  
  cancelrow2()
  {
    var selectedData = this.gridApi.getSelectedRows();
    var res = this.gridApi.updateRowData({ remove: selectedData });
    this.addbutton = false;
  }



onGridReady(params) {
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
}

}

function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: '' 
  };
  newCount++;
  return newData;
}






function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}

@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialog2Component implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  Clientes2: any ;
  @Input() sideBar: RrhhComponent;
  choosedcolor: any = '#ff0000';
 
  
  

  ngOnInit() {
 

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
     
    }
    
    ngOnDestroy() {
     /*  this._onDestroy.next();
      this._onDestroy.complete(); */
    }
    




  




  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialog2Component>, private rrhh: RrhhService) {}
    
    saveData(bankCtrl){
      console.log(bankCtrl);

    
      this.dialogRef.close(bankCtrl);
      }

}
