import { FuseMatSidenavHelperDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { Component, ViewChild, OnInit, RendererStyleFlags2, OnDestroy, AfterViewInit, Input, Inject } from '@angular/core';
import {ProyectosService} from './proyectos.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Bank, BANKS } from '../demo-data';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
/* interface Bank {
  id: string;
  name: string;
} */
@Component({
  selector: 'fuse-app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {
  public loading = false;
  public gridApi;
  private gridColumnApi;
  public gridOptions;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  clientetoadd: any;
  datatosend: any;
  sometrig: any;
  datatomod: any;
  columnDefs: any;
  personas: any;
  carMappings: any;
  carMappings_R: any;
  carMappings_R3: any;
  columnTypes: any;
  serviciostosend: any;
  sectorid: any;
  serviciostoadd: any[];
  presup: any[];
  personastoadd: any;
  rowData: any;
  arm: any;
  public trig2;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;  private components;
  constructor(private proyectos: ProyectosService, private router: Router,
    private loginService1: LoginService,   public dialog: MatDialog) { 
    this.editType = 'fullRow';
    this.rowSelection = 'single';

  }

  ngOnInit() {
    this.trigger = false;
   // this.getPersonas();
    this.trig2 = false;
    this.sometrig = false;
   // this.getSectores();
   if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
    console.log(localStorage)
    this.router.navigate(['/login']);
  } else {
    this.loginService1.checkToken().subscribe(rescat => {
      console.log(rescat);
      if (rescat.response){
        if (rescat.response === 1){


          
          this.getPersonas();
      }
      else{

        this.router.navigate(['/login']);
      }
    }
      else{
        this.router.navigate(['/login']);
      }

    })
      }

     // this.getProyectos(this.carMappings, this.carMappings_R)

    


         // this.getProyectos(this.carMappings);
  }
  getPersonas(){

    this.proyectos.getRRHH2().subscribe(res1 => {
      console.log(res1);
         var carMappings2 = res1.rrhh;
       var result = {};
          for (var i = 0; i < carMappings2.length; i++) {
            result[carMappings2[i].id] = carMappings2[i].nombre;
          }
          this.carMappings_R = result;
      console.log(result);
    // this.getSectores(this.carMappings_R);
    this.getSectores(this.carMappings_R)
          });
  }

  getSectores(data2){
    
    this.proyectos.getSectores().subscribe(res1 => {
      console.log(res1);
         var carMappings1 = res1.sectores;
       var result = {};
          for (var i = 0; i < carMappings1.length; i++) {
            result[carMappings1[i].id] = carMappings1[i].nombre;
          }
          this.carMappings = result;
      console.log(result);
      this.getProyectos(this.carMappings, data2)
          });


  }
  openDialog(rowindex, dataid): void {
   /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
    console.log(dataformod2);
    this.proyectos.modProyectos(dataformod2.data)
    .subscribe(res => {
        
      console.log(res);
          }); */
   

    const dialogRef = this.dialog.open(DialogDataExampleDialogComponent, {
      width: '450px',
      height: '450px',
      
    });
  
    dialogRef.afterClosed().subscribe(result => {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
      rowNode.setDataValue('cliente',  result.nombre);
     // this.armtest = result.age;
     var dataformod = this.gridApi.getRowNode(rowindex);
     console.log(dataformod);
     console.log(dataformod.data.id);
     console.log(result.id_cliente);
     this.clientetoadd = result.id_cliente;
/*      if (dataid.id){
     this.proyectos.modProyectos2(dataformod.data.id, result.id_cliente)
     .subscribe(res => {
         
       console.log(res);
           });
          }
          else{

this.clientetoadd = result.id_cliente;
console.log(this.clientetoadd)
          } */
    });
  }


  openDialog2(rowindex, dataid): void {
    /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
     console.log(dataformod2);
     this.proyectos.modProyectos(dataformod2.data)
     .subscribe(res => {
         
       console.log(res);
           }); */
    
 
     const dialogRef2 = this.dialog.open(DialogDataExampleDialog2Component, {
       width: '450px',
       height: '450px',
       
     });
   
     dialogRef2.afterClosed().subscribe(result => {
       var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
       console.log(result);

       var names = result.map(function(item) {
        return item['nombre'];
      });
      this.personas = result.map(function(item) {
        return item['id'];
      });

       rowNode.setDataValue('personas_encargados',  names);
      // this.armtest = result.age;
      var dataformod = this.gridApi.getRowNode(rowindex);
      console.log(dataformod);
      console.log(dataformod.data.id);
      console.log(result.id_cliente);
      this.personastoadd = names;

      if (dataformod.data.id && !this.addbutton){
        this.proyectos.modProyectosPersonas(dataformod.data.id, this.personas)
        .subscribe(res => {
            
          console.log(res);
              });
             }
/*       if (dataid.id){
      this.proyectos.modProyectos2(dataformod.data.id, result.id_cliente)
      .subscribe(res => {
          
        console.log(res);
            });
           }
           else{
 
 this.clientetoadd = result.id_cliente;
 console.log(this.clientetoadd)
           } */
     });
   }

   openDialog4(): void {
    const dialogRef4 = this.dialog.open(DialogDataExampleDialog4Component, {
      width: '450px',
      height: '450px',
      
    });
    dialogRef4.afterClosed().subscribe(result => {

    })
   }



   openDialog5(rowindex, dataid): void {

    console.log(rowindex);
 
     const dialogRef5 = this.dialog.open(DialogDataExampleDialog5Component, {
       width: '450px',
       height: '450px',
       data: { data: dataid},
       
     });
   
     dialogRef5.afterClosed().subscribe(result => {
       var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
       console.log(result);

       var names = result.map(function(item) {
        return item['nombre'];
      });
      this.sectorid = result.map(function(item) {
        return item['id'];
      });
   console.log(this.sectorid);

       rowNode.setDataValue('sector',  names);
      // this.armtest = result.age;
      var dataformod = this.gridApi.getRowNode(rowindex);
      console.log(dataformod);
      console.log(dataformod.data.id);
      console.log(names);
      this.personastoadd = names;
      if (dataformod.data.id && !this.addbutton){
        this.proyectos.modProyectosSector(dataformod.data.id, this.sectorid)
        .subscribe(res => {
            
          console.log(res);
              });
             }
     });
   }




   openDialog3(rowindex, dataid): void {
    /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
     console.log(dataformod2);
     this.proyectos.modProyectos(dataformod2.data)
     .subscribe(res => {
         
       console.log(res);
           }); */
    
 
     const dialogRef3 = this.dialog.open(DialogDataExampleDialog3Component, {
       width: '600px',
       height: '600px',
       
     });
   
     dialogRef3.afterClosed().subscribe(result => {
       var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
       console.log(result);

       var names = result.map(function(item) {
        return item['name'];
      });
      var serviciosid = result.map(function(item2) {
        return item2['id'];
      });
      var presup = result.map(function(item3) {
        return item3['importe'];
      });
console.log(serviciosid);
console.log(presup);

/* 
var arman = [];
arman[1] = "x";
arman[2] = "y";
console.log(arman); */

       rowNode.setDataValue('servicios',  names);
      // this.armtest = result.age;
      var dataformod = this.gridApi.getRowNode(rowindex);
      console.log(dataformod);
      console.log(dataformod.data.id);
      
      console.log(result.id_cliente);
      this.serviciostoadd = serviciosid;
      this.presup = presup;
      console.log(this.serviciostoadd);
      this.serviciostosend = names;
      if (dataformod.data.id && !this.addbutton){
      this.proyectos.modProyectosXXX(dataformod.data.id, this.serviciostoadd, dataformod.data.id, dataformod.data.clientID, presup)
      .subscribe(res => {
          
        console.log(res);
            });
           }
           else{
 
/*  this.clientetoadd = result.id_cliente;
 console.log(this.clientetoadd) */
           }
     });
   }

  getProyectos(data1, data2){
    function extractValues(mappings) {
      return Object.keys(mappings);
    }
    function lookupValue(mappings, key) {
      return mappings[key];
    }
    function lookupKey(mappings, name) {
      for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
            return key;
          }
        }
      }
    }
    this.columnDefs = [];
    this.loading = true;
    this.proyectos.getProyectos()
    .subscribe(res => {
      console.log(res);
      this.rowData = res.proyectos;
      console.log(res);

        for (let key in res.proyectos[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', hide: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }        else  if (key === 'codigo_postal') {
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100 };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }
      else  if (key === 'clientID') {
        this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, hide: true };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
      else  if (key === 'cliente') {
        this.dat = {headerName: key, field: key, editable: false,  filter: 'agTextColumnFilter' };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
    else  if (key === 'servicios') {
      this.dat = {headerName: key, field: key, editable: false,  filter: 'agTextColumnFilter' };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
  else  if (key === 'personas_encargados') {
    this.dat = {headerName: key, field: key, editable: false,  filter: 'agTextColumnFilter' };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
      else  if (key === 'fecha_ini') {
        this.dat = {headerName: key, field: key, editable: true,   width: 100,        cellEditor: "datePicker"
      
      
      };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);   //  type: ["dateColumn", "nonEditableColumn"] cellEditor: "datePicker", 
        

    }
/*     else  if (key === 'sector_id') {
      this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 10,  hide: true,  type: 'numericColumn'};
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  } */
  else  if (key === 'sector') {
    this.dat = {headerName: key, field: key,  editable: false,  
 /*    cellEditorParams: {values: extractValues(data1) },
    valueFormatter: function(params) {
      return lookupValue(data1, params.value);
    },
    valueParser: function(params) {
      return lookupKey(data1, params.newValue);
    } */
};
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'manager') {
  this.dat = {headerName: key, field: key,  editable: true,  cellEditor: "agSelectCellEditor",
  cellEditorParams: {values: extractValues(data2) },
  valueFormatter: function(params) {
    return lookupValue(data2, params.value);
  },
  valueParser: function(params) {
    return lookupKey(data2, params.newValue);
  }
};
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    
          this.loading = false;


      });



      this.components = { datePicker: getDatePicker() };
      function getDatePicker() {
      
        function Datepicker() {}
        
        Datepicker.prototype.init = function(params) {
          this.eInput = document.createElement("input");
          this.eInput.value = params.value;
          $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
        };
        Datepicker.prototype.getGui = function() {
          return this.eInput;
        };
        Datepicker.prototype.afterGuiAttached = function() {
          this.eInput.focus();
          this.eInput.select();
        };
        Datepicker.prototype.getValue = function() {
          return this.eInput.value;
        };
        Datepicker.prototype.destroy = function() {};
        Datepicker.prototype.isPopup = function() {
          return false;
        };
      
        return Datepicker;
      
      }



      

/*       this.columnTypes = {
        nonEditableColumn: { editable: false },
        dateColumn: {
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: function(filterLocalDateAtMidnight, cellValue) {
              var dateParts = cellValue.split("/");
              var day = Number(dateParts[2]);
              var month = Number(dateParts[1]) - 1;
              var year = Number(dateParts[0]);
              var cellDate = new Date(day, month, year);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        }
      } */


      
  }
  onCellDoubleClicked(event1: any) { console.log('doubleclick', event1); 
  if (event1.colDef.field === 'nombre' ){ 
    
    var armindex = this.gridApi.getFocusedCell();
    console.log(armindex);
    // this.gridApi.clearFocusedCell();
   
    var col = this.gridColumnApi.getColumn("fecha_ini");

    // obtain the column definition from the column
    var colDef = col.getColDef();
    console.log(colDef);
    // update the header name
    colDef.editable = false;
  }
  else if (event1.colDef.field === 'cliente' ){ 

    var armindex = this.gridApi.getFocusedCell();
    console.log(armindex);
    // this.gridApi.clearFocusedCell();
    this.openDialog(armindex.rowIndex, event1.data);
    var col = this.gridColumnApi.getColumn("fecha_ini");

    // obtain the column definition from the column
    var colDef = col.getColDef();
    console.log(colDef);
    // update the header name
    colDef.editable = false;
  }
else if (event1.colDef.field === 'personas_encargados' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  // this.gridApi.stopEditing();
  this.openDialog2(armindex2.rowIndex, event1.data);
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
  var colDef = col.getColDef();
  
  // update the header name
 // colDef.editable = false;
}
else if (event1.colDef.field === 'sector' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  console.log(event1);

  this.openDialog5(armindex2.rowIndex, event1.data);
//   this.gridApi.stopEditing();
  
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
  var colDef = col.getColDef();
  
  // update the header name
//  colDef.editable = false;
}
else if (event1.colDef.field === 'servicios' ){
  var armindex2 = this.gridApi.getFocusedCell();
  this.gridApi.setFocusedCell(0, "estado", null)
  console.log(armindex2);
   // this.gridApi.stopEditing();
 var col = this.gridColumnApi.getColumn("fecha_ini");

 // obtain the column definition from the column
 var colDef = col.getColDef();
 
 // update the header name
// colDef.editable = false;
if ((event1.data.nombre !== "undefined"  && event1.data.nombre !== "" && event1.data.nombre && event1.data.cliente !== "undefined" && event1.data.cliente !== "" && event1.data.cliente) ||  (this.addbutton === true) ){
  this.openDialog3(armindex2.rowIndex, event1.data);

}
else{
  this.openDialog4();
  
}




}
else if (event1.colDef.field === 'estado' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  // this.gridApi.stopEditing();
  
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
  var colDef = col.getColDef();
  
  // update the header name
 // colDef.editable = false;
}
else if (event1.colDef.field === 'manager' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
 //  this.gridApi.stopEditing();
 
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
  var colDef = col.getColDef();
  
  // update the header name
 // colDef.editable = false;
}
else if (event1.colDef.field === 'comentarios' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  
  
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
//  var colDef = col.getColDef();
  
  // update the header name
 // colDef.editable = false;
}
else if (event1.colDef.field === 'fecha_ini' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  
  


var col = this.gridColumnApi.getColumn("fecha_ini");

// obtain the column definition from the column
var colDef = col.getColDef();

// update the header name
colDef.editable = true;

// the column is now updated. to reflect the header change, get the grid refresh the header


}

}


  
  saveRow(){
this.sometrig = true;
    var dataformod2 = this.gridApi.getDisplayedRowAtIndex(0);
    console.log(dataformod2);
    this.gridApi.stopEditing();
/*     if (!dataformod2.data.servicios && !dataformod2.data.personas_encargados) {

      dataformod2.data.servicios = null;
      dataformod2.data.personas_encargados = null;
  
      }
      else if (!dataformod2.servicios){
  
        dataformod2.data.servicios = null;
        this.serviciostosend = null;
      }
      else if (!dataformod2.personas_encargados){
  
        dataformod2.data.personas_encargados = null;
      }

    if (dataformod2.data.nombre === "" || !dataformod2.data.cliente ){
      this.openDialog4();

    }
  
    else{





      
    console.log(dataformod2.data);
    console.log(this.clientetoadd);
   
    this.proyectos.addProyectos(dataformod2.data, this.clientetoadd, this.serviciostosend)
    
    .subscribe(res => {
      this.gridApi.stopEditing();
console.log(res);
    });
  
  } */

  } 
  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }
  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    console.log(res);
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  onRowClicked(event: any) { console.log('row', event);
  this.butonrowclicked = true;
}

  onRemoveSelected() {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.proyectos.remProyectos(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }

 

  onRowValueChanged(event: any){console.log('onRowValueChanged', event.data);
  this.datatomod = event.data;
  if (this.datatomod.id){
    console.log(this.datatomod);


    if (this.serviciostosend === undefined && this.clientetoadd === undefined && this.personastoadd === undefined){
      console.log("this.datatomod.servicios");
      this.proyectos.modProyectos2(this.datatomod) .subscribe(res => {
        
        console.log(res);
            });
    }
    else if (this.serviciostosend === undefined && this.clientetoadd === undefined) {
      this.proyectos.modProyectos3(this.datatomod, this.personastoadd) .subscribe(res => {
        
        console.log(res);
            });
          
          }
          else if (this.serviciostosend === undefined ) {
            this.proyectos.modProyectos4(this.datatomod, this.personastoadd, this.clientetoadd) .subscribe(res => {
              
              console.log(res);
                  });
                
                }
                else if (this.clientetoadd === undefined ) {
                  this.proyectos.modProyectos5(this.datatomod, this.personastoadd, this.serviciostoadd, this.serviciostosend) .subscribe(res => {
                    
                    console.log(res);
                        });
                      
                      }
                      else if (this.personastoadd === undefined ) {
                        this.proyectos.modProyectos6(this.datatomod, this.serviciostoadd, this.serviciostosend, this.clientetoadd ) .subscribe(res => {
                          
                          console.log(res);
                              });
                            
                            }

                            else if (this.personastoadd === undefined && this.clientetoadd === undefined) {
                              this.proyectos.modProyectos7(this.datatomod, this.serviciostoadd, this.serviciostosend) .subscribe(res => {
                                
                                console.log(res);
                                    });
                                  
                                  }   
                                  else if (this.serviciostosend === undefined && this.personastoadd === undefined ) {
                                    this.proyectos.modProyectos8(this.datatomod, this.clientetoadd) .subscribe(res => {
                                      
                                      console.log(res);
                                          });
                                        
                                        }   


else{


    this.proyectos.modProyectos(this.datatomod, this.serviciostosend,  this.clientetoadd,  this.personastoadd, this.serviciostoadd)
    .subscribe(res => {
        
      console.log(res);
          });
        }
  // this.modclient();
  }
  else{

    if (this.datatomod.servicios && !this.datatomod.personas_encargados) {

      this.datatomod.servicios = null;
      this.datatomod.personas_encargados = null;
  
      }
      else if (!this.datatomod.servicios){
  
        this.datatomod.servicios = null;
        this.serviciostosend = null;
      }
      else if (!this.datatomod.personas_encargados){
  
        this.datatomod.personas_encargados = null;
      }

    if (this.datatomod.nombre === "" || !this.datatomod.cliente ){
      this.openDialog4();

    }
  
    else{




      this.addbutton = false;
      
    console.log(this.datatomod);
    console.log(this.clientetoadd);
    console.log(this.serviciostoadd);
    this.proyectos.addProyectos(this.datatomod, this.clientetoadd,  this.sectorid, this.personas, this.serviciostoadd, this.presup)
    
    .subscribe(res => {
   //   this.gridApi.stopEditing();
console.log(res);
    });
  
  }



  }



  }
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    console.log(params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  Clientes2: any ;
  @Input() sideBar: ProyectosComponent;
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */

  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.proyectos.getClientes()
    .subscribe(res => {
this.clientes = res.clientes2;
console.log(this.clientes)
this.bankCtrl.setValue(this.clientes[10]);
    

    // load the initial bank list
    this.filteredBanks.next(this.clientes.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.clientes) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.clientes.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.clientes.filter(clientes => clientes.nombre.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>, private proyectos: ProyectosService) {}
    
    saveData(bankCtrl){
      console.log(bankCtrl.value.nombre);
      this.dialogRef.close({id_cliente: bankCtrl.value.id, nombre: bankCtrl.value.nombre});
      }
      saveData1(res){
        console.log(res);
        this.dialogRef.close({age: this.armtest, username: this.username});
        }
}





@Component({
  selector: 'fuse-dialog-data-example-dialog2',
  templateUrl: 'dialog-data-example-dialog2.html',
})
export class DialogDataExampleDialog2Component implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  Clientes2: any ;
  @Input() sideBar: ProyectosComponent;
  
 
  private pers_enc: any; 
    
  /** control for the selected bank for multi-selection */
  public bankMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public bankMultiFilterCtrl: FormControl = new FormControl();

  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  public filteredBanksMulti: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('multiSelect') multiSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.proyectos.getRRHH2()
    .subscribe(res => {
this.pers_enc = res.rrhh;
console.log(this.pers_enc)
/* this.bankMultiCtrl.setValue([this.pers_enc[1], this.pers_enc[2], this.pers_enc[3]]); */
    

    // load the initial bank list
    
    this.filteredBanksMulti.next(this.pers_enc.slice());
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });
    })
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
         
          this.multiSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
        });
    }



    private filterBanksMulti() {
      if (!this.pers_enc) {
        return;
      }
      // get the search keyword
      let search = this.bankMultiFilterCtrl.value;
      if (!search) {
        this.filteredBanksMulti.next(this.pers_enc.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.filteredBanksMulti.next(
        this.pers_enc.filter(bank => bank.nombre.toLowerCase().indexOf(search) > -1)
      );
    }




  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef2: MatDialogRef<DialogDataExampleDialog2Component>, private proyectos: ProyectosService) {}
    
    saveData2(bankCtrl){
      console.log(bankCtrl.value);
      this.dialogRef2.close(bankCtrl.value);
      }

}










@Component({
  selector: 'fuse-dialog-data-example-dialog3',
  templateUrl: 'dialog-data-example-dialog3.html',
})
export class DialogDataExampleDialog3Component implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  servicios: any ;
  @Input() sideBar: ProyectosComponent;
  words: any;
  
  private servicio: any; 
  screenForm: FormGroup;



  /** control for the selected bank for multi-selection */
  public bankMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public bankMultiFilterCtrl: FormControl = new FormControl();

  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  public filteredBanksMulti: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('multiSelect') multiSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
   
    this.words = [{value: 'word1'}, {value: 'word2'}, {value: 'word3'}];
    this.proyectos.getServicios2()
    .subscribe(res => {
this.servicio = res.servicios2;
console.log(this.servicio)
/* this.bankMultiCtrl.setValue([this.servicio[1], this.servicio[2], this.servicio[3]]); */
    

    // load the initial bank list
    
    this.filteredBanksMulti.next(this.servicio.slice());
    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });
    })
  

    // listen for search field value changes

    }


    private setScreenShots(app: any): void {
      
          const screenShots = app.map(nombre => {
                  return this.fb.group({
                      nombre: [nombre, Validators.required],
                      importe: ['', Validators.required],
                      name: ['', Validators.required],
                      id:  ['', Validators.required]


                  });
              }
              
          );
          console.log(screenShots);
          const screenShotsArray = this.fb.array(screenShots);
console.log(screenShotsArray);
          this.screenForm.setControl('iPhoneScreenshots', screenShotsArray);
      
  }
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
         
          this.multiSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
        });
    }



    private filterBanksMulti() {
      if (!this.servicio) {
        return;
      }
      // get the search keyword
      let search = this.bankMultiFilterCtrl.value;
      if (!search) {
        this.filteredBanksMulti.next(this.servicio.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.filteredBanksMulti.next(
        this.servicio.filter(bank => bank.nombre.toLowerCase().indexOf(search) > -1)
      );
    }




  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef3: MatDialogRef<DialogDataExampleDialog3Component>, private proyectos: ProyectosService, private fb: FormBuilder) {

      this.screenForm = this.fb.group({
        iPhoneScreenshots: this.fb.array([]),
    });
    }
    onSubmit(data){
      this.dialogRef3.close(data.iPhoneScreenshots);
      console.log(data);
    }
    saveData3(bankCtrl){
      let app = { screenshotUrls: ['http://lorempixel.com/400/200', 'http://lorempixel.com/400/200'] };
      var finalresult = bankCtrl.value.map(a => a.id);
      console.log(finalresult)
      this.setScreenShots(bankCtrl.value)
      console.log(bankCtrl.value);
     // this.dialogRef3.close(bankCtrl.value);
    
    //  this.dialogRef3.close(bankCtrl.value);
      }

}









@Component({
  selector: 'fuse-dialog-data-example-dialog4',
  templateUrl: 'dialog-data-example-dialog4.html',
})
export class DialogDataExampleDialog4Component implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  servicios: any ;
  @Input() sideBar: ProyectosComponent;
  
 
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {

/* this.bankMultiCtrl.setValue([this.servicio[1], this.servicio[2], this.servicio[3]]); */
    



    }


    ngAfterViewInit() {
     
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    








  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef4: MatDialogRef<DialogDataExampleDialog4Component>, private proyectos: ProyectosService) {}
    
    saveDataClose(){

      this.dialogRef4.close();
      }

}


@Component({
  selector: 'fuse-dialog-data-example-dialog5',
  templateUrl: 'dialog-data-example-dialog5.html',
})
export class DialogDataExampleDialog5Component implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  
  @Input() sideBar: ProyectosComponent;
  words: any;
  
  private sectores: any; 
  screenForm: FormGroup;

  protected banks: Bank[] = BANKS;

  /** control for the selected bank for multi-selection */
  public bankMultiCtrl1: FormControl = new FormControl(); 

  /** control for the MatSelect filter keyword multi-selection */
  public bankMultiFilterCtrl1: FormControl = new FormControl();


  
  
  public filteredBanksMulti1: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('multiSelect') multiSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    console.log(this.banks)
   
    this.proyectos.getSectores()   
    .subscribe(res => {
this.sectores = res.sectores;
console.log(this.sectores)
// let newres = this.sectores.findIndex(x => x.id === 1);
// console.log(newres)
  this.bankMultiCtrl1.setValue([this.sectores[10]]);
    

    // load the initial bank list
    
    this.filteredBanksMulti1.next(this.sectores.slice());
    this.bankMultiFilterCtrl1.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti1();
      });
    })
  

    // listen for search field value changes

    }


/*     private setScreenShots(app: any): void {
      
          const screenShots = app.map(nombre => {
                  return this.fb.group({
                      nombre: [nombre, Validators.required],
                      importe: ['', Validators.required],
                      name: ['', Validators.required],
                      id:  ['', Validators.required]


                  });
              }
              
          );
          console.log(screenShots);
          const screenShotsArray = this.fb.array(screenShots);
console.log(screenShotsArray);
          this.screenForm.setControl('iPhoneScreenshots', screenShotsArray);
      
  } */
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanksMulti1
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
         
          this.multiSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
        });
    }



    private filterBanksMulti1() {
      if (!this.sectores) {
        console.log("hola");
        return;
      }
      // get the search keyword
      let search = this.bankMultiFilterCtrl1.value;
      console.log(search)
      if (!search) {
        this.filteredBanksMulti1.next(this.sectores.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.filteredBanksMulti1.next(
        this.sectores.filter(bank => bank.nombre.toLowerCase().indexOf(search) > -1)
      );
    }




  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef5: MatDialogRef<DialogDataExampleDialog5Component>, private proyectos: ProyectosService, private fb: FormBuilder,  @Inject(MAT_DIALOG_DATA) public data: any) {

      this.screenForm = this.fb.group({
        iPhoneScreenshots: this.fb.array([]),
    });
    }
    onSubmit(data){
      this.dialogRef5.close(data.value);
      console.log(data.value);
    }
    saveData3(bankCtrl){
      let app = { screenshotUrls: ['http://lorempixel.com/400/200', 'http://lorempixel.com/400/200'] };
      var finalresult = bankCtrl.value.map(a => a.id);
      console.log(finalresult)
     // this.setScreenShots(bankCtrl.value)
      console.log(bankCtrl.value);
      // this.dialogRef5.close(bankCtrl.value);
     // this.dialogRef3.close(bankCtrl.value);
    
    //  this.dialogRef3.close(bankCtrl.value);
      }

}










