
import {Injectable} from '@angular/core';
// import {AuthHttp} from 'angular2-jwt';
import {Response, Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Injectable(/* {
  providedIn: 'root'
} */)
export class ServiciosService {
  private baseUrl = 'http://lfdi.bitronedev.com/public/';
constructor(public http: Http ) {


   }

   getServicios(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}servicios/getServicios`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  addServicios(datatosend): Observable<any> {
    console.log(datatosend);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'nombre=' + datatosend.nombre + '&plazo=' + datatosend.plazo + '&precio=' + datatosend.precio ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}servicios/addServicios`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }



  modServicios( datatomod ): any {
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id + '&nombre=' + datatomod.nombre + '&plazo=' + datatomod.plazo + '&precio=' + datatomod.precio  ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}servicios/modServicios`, body,  options).map(this.extractData).catch(this.handleError);
  }














  remServicios(datatosend): Observable<any> {
   
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + datatosend  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}servicios/remServicios`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }
  /**
   * Extracting data.
   *
   * @param res
   * @returns {any|{}}
   */
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private extractData2(res: Response) {
    let body = res;
    return body || {};
  }
 /**
  * Handling errors.
  *
  * @param error
  * @returns {ErrorObservable}
  */
 private handleError(error: any) {
   let errMsg = (error.message) ? error.message :
     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
   console.log(errMsg); // log to console instead
   return Observable.throw(errMsg);
 }
}

