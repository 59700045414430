import { Injectable } from '@angular/core';
import {Response, Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Injectable({
  providedIn: 'root'
})
export class ServiceEnLineService {

  private baseUrl = 'http://lfdi.bitronedev.com/public/';
  constructor(public http: Http ) {
  }

getServiceEnLine(): any {
  let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
  let authHeader = new Headers();

  if (jwt) {
    authHeader.append('Authorization', jwt);
  }

  let options = new RequestOptions({headers: authHeader});

  return this.http.get(`${this.baseUrl}servicios/getServiceEnLine`, options)
    .map(this.extractData)
    .catch(this.handleError);
}

getRRHH2(): any {
  let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
  let authHeader = new Headers();

  if (jwt) {
    authHeader.append('Authorization', jwt);
  }

  let options = new RequestOptions({headers: authHeader});

  return this.http.get(`${this.baseUrl}rrhh/getRRHH2`, options)
    .map(this.extractData)
    .catch(this.handleError);
}




addServiceEnLine(datatosend): Observable<any> {
  console.log(datatosend);
  let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
  let authHeader = new Headers();
  authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
  
  if (jwt) {
    authHeader.append('Authorization', jwt);
  }
  let body = 'nombre=' + datatosend.nombre + '&presupuesto=' + datatosend.presupuesto ;
  
  let options = new RequestOptions({headers: authHeader});

  return this.http.post(`${this.baseUrl}ServiceEnLine/addServiceEnLine`, body, options)
    .map(this.extractData)
    .catch(this.handleError);
}



modServiceEnLine( datatomod ): any {
  
  // let jwt = localStorage.getItem('id_token');
 // let user_id= localStorage.getItem('user_login');
 let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
 let body = 'id=' + datatomod.id + '&quien_hace=' + datatomod.quien_hace + '&fecha_inicio=' + datatomod.fecha_inicio + '&fecha_prev_entrega=' + datatomod.fecha_prev_entrega + '&fecha_entrega=' + datatomod.fecha_entrega + '&cerrado=' + datatomod.cerrado + '&estado=' + datatomod.estado + '&pagado=' + datatomod.pagado + '&en_proceso=' + datatomod.en_proceso + '&pres_a_disponer=' + datatomod.pres_a_disponer + '&gastado=' + datatomod.gastado ;
  let authHeader = new Headers();

  if (jwt) {
      authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

    authHeader.append('Authorization', jwt);
  //  console.log(authHeader);
  }

  let options = new RequestOptions({headers: authHeader});
//   console.log(body);

  return this.http.post(`${this.baseUrl}servicios/modServiceEnLine`, body,  options).map(this.extractData2).catch(this.handleError);
}














remServiceEnLine(datatosend): Observable<any> {
 
  let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
  let authHeader = new Headers();
  authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
  
  if (jwt) {
    authHeader.append('Authorization', jwt);
  }
  let body = 'id=' + datatosend  ;
  
  let options = new RequestOptions({headers: authHeader});

  return this.http.post(`${this.baseUrl}ServiceEnLine/remServiceEnLine`, body, options)
    .map(this.extractData2)
    .catch(this.handleError);
}
/**
 * Extracting data.
 *
 * @param res
 * @returns {any|{}}
 */
private extractData(res: Response) {
  let body = res.json();
  return body || {};
}

private extractData2(res: Response) {
  let body = res;
  return body || {};
}
/**
* Handling errors.
*
* @param error
* @returns {ErrorObservable}
*/
private handleError(error: any) {
 let errMsg = (error.message) ? error.message :
   error.status ? `${error.status} - ${error.statusText}` : 'Server error';
 console.log(errMsg); // log to console instead
 return Observable.throw(errMsg);
}



}

